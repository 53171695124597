<!-- 配送单列表 -->
<template>
  <div class="tablePosiBox">
    <ul class="gratisTableBox">
      <li v-for="(tableItem, tableIndex) in tableData" :key="tableIndex">
        <h2>
          <span>{{ $fanyi("订单号") }}：{{ tableItem.order_sn }}</span>
          <span>
            <img :src="require('@/assets/icon/dingdanfanhao.png')" alt="" />
            番号：#{{ tableItem.sorting }}
          </span>
        </h2>
        <div class="goodsList">
          <div class="productInformation">
            <input
              type="checkbox"
              v-if="['delete'].includes($parent.userOperationMode)"
              :checked="tableItem.checked"
              @click="
                tableItem.checked = !tableItem.checked;
                $parent.oddChoice(tableIndex);
              "
            />
            <van-image
              lazy-load
              class="productImg"
              :src="tableItem.pic"
              @click="
                $fun.toCommodityDetails(
                  tableItem.goods_id,
                  tableItem.from_platform
                )
              "
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <div class="infoBox">
              <h3>{{ tableItem.goods_title }}</h3>
              <div class="detailBox" @click="openDetailBox(tableItem)">
                <span
                  v-for="(
                    order_detaildetailitem, order_detaildetailindex
                  ) in tableItem.detail"
                  :key="order_detaildetailindex"
                >
                  {{ order_detaildetailitem.value }};
                </span>
              </div>
              <div class="priceBox">
                <span class="redFont">
                  <span class="bigFont">
                    {{ $fun.ceil(tableItem.confirm_price) }}
                    {{ $fanyi("元") }}
                    <span class="secBig">
                      ({{
                        $fun.JPYNumSegmentation(
                          $fun.roundNumber(
                            tableItem.confirm_price *
                              $parent.deliveryOrderExchangeRate
                          )
                        )
                      }}
                      {{ $fanyi("円") }})
                    </span>
                  </span>
                  <!-- <span class="smallFont">
                    ({{
                      $fun.JPYNumSegmentation(
                        $fun.roundNumber(
                          tableItem.confirm_price * $store.state.exchangeRate
                        )
                      )
                    }}
                    円 )
                  </span> -->
                </span>
                <span class="grayFont">X {{ tableItem.confirm_num }} </span>
              </div>
            </div>
          </div>
          <div class="numRow">
            <div class="normalOpt greenOpt">
              <span
                >{{ $fanyi("发送可能数") }}:{{ tableItem.send_await_num }}</span
              >
              <span>
                {{ $fanyi("提货数量") }}:
                <input
                  type="text"
                  @change="pickupQuantityLimit(tableItem)"
                  v-model="tableItem.send_num"
                  oninput="value=value.replace(/[^\d]/g,'')"
                />
              </span>
            </div>
          </div>
          <div
            class="numRow"
            v-for="(tagItem, tagIndex) in tableItem.porder_detail_tag"
            :key="tagIndex"
          >
            <div
              class="productTag"
              @click="openProductTagEditorPopup(tableItem, tagIndex)"
            >
              <img :src="require('@/assets/icon/xiatubiao.png')" alt="" />
              <p
                class="placeFont"
                v-if="
                  !tableItem.porder_detail_tag ||
                  !tableItem.porder_detail_tag.length
                "
              >
                {{ $fanyi("选择标签信息") }}
              </p>
              <template v-else>
                <div>
                  <div class="u-line">
                    {{ tagItem.type_translate }}
                  </div>
                  <div class="u-line">
                    {{ tagItem.no }}
                  </div>
                  <div class="u-line">
                    {{ tagItem.goods_no }}
                  </div>
                </div>
              </template>
            </div>
          </div>
          <div class="numRow">
            <div
              class="productTag"
              @click="openProductTagEditorPopup(tableItem)"
            >
              <img
                class="addIconimg"
                :src="require('@/assets/icon/addIcon.png')"
                alt=""
              />
              <p class="placeFont">
                {{ $fanyi("选择标签信息") }}
              </p>
            </div>
          </div>
          <textarea
            class="remarkBox"
            v-autoHeightForLineBreak
            v-model="tableItem.client_remark"
            :placeholder="$fanyi('请输入单番装箱备注')"
          ></textarea>
        </div>
      </li>
    </ul>
    <editProductTag ref="editProductTagRef" />
    <detailsAllShowVue ref="detailsAllShowVueRef" />
  </div>
</template>
<script setup>
import detailsAllShowVue from "@/components/detailsAllShow";
import editProductTag from "./editProductTag.vue";
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const tableData = computed(() => {
  return proxy.$parent.tableData;
});
// 提货数量限制
const pickupQuantityLimit = (data) => {
  if (data.send_num > data.send_await_num) {
    data.send_num = data.send_await_num;
    return proxy.$message.warning(proxy.$fanyi("提货数量不能超过可提货数量"));
  } else if (data.send_num == 0) {
    data.send_num = 1;
    return proxy.$message.warning(proxy.$fanyi("提货数量不能为0"));
  }
};
const userOperationMode = computed(() => {
  return proxy.$parent.userOperationMode;
});
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
// 打开商品标签编辑弹窗
const openProductTagEditorPopup = (data, tagIndex) => {
  proxy.$refs.editProductTagRef.open(data, tagIndex);
};
// 打开商品详情弹窗
const openDetailBox = (data) => {
  // 将需要的数据传入
  proxy.$refs.detailsAllShowVueRef.open({
    goods_title: data.goods_title,
    detail: data.detail,
    pic: data.pic,
    sort: data.sorting,
  });
};
defineExpose({ userOperationMode });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.tablePosiBox {
  min-height: 70vh;

  position: relative;
  .gratisTableBox {
    margin: 0 auto;
    width: 690px;
    li {
      margin: 20px 0 1px;
      padding: 0 30px 30px;
      background-color: white;
      overflow: hidden;
      &.openrowd {
        max-height: unset;
      }
      h2 {
        height: 100px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        img {
          width: 26px;
          height: 26px;
          margin-right: 9px;
        }
        .van-icon-arrow-down {
          margin-left: auto;
          transition: 0.3s;
          font-size: 30px;
        }
        .van-icon-arrow-down.Collapse {
          rotate: -180deg;
        }
        span {
          font-size: 28px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      // 订单内商品
      .goodsList {
        padding-bottom: 1px;
        margin-bottom: 30px;
        border-bottom: dashed 1px #dfdfdf;
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
        .outfit {
          display: flex;
          align-items: center;
          height: 42px;

          margin-bottom: 20px;
          span {
            font-size: 28px;
          }
        }
        .productInformation {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          input[type="checkbox"] {
            width: 30px;
            flex: 0 0 30px;
            margin-right: 20px;
            height: 30px;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
          }
          .productImg {
            $size: 180px;
            flex: 0 0 $size;
            width: $size;
            height: $size;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 20px;
          }
          .infoBox {
            h3 {
              font-size: 28px;
              line-height: 42px;
              height: 84px;
              margin-bottom: 5px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .detailBox {
              width: 350px;
              height: 42px;
              line-height: 39px;
              background: #fafafa;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 10px;
              margin-bottom: 10px;
              font-size: 22px;

              span {
                line-height: 39px;
                font-size: 22px;
                color: #999999;
              }
            }
            .priceBox {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .redFont {
                color: #b4272b;
              }
              .bigFont {
                font-size: 28px;
                font-weight: 600;
                .secBig {
                  font-size: 24px;
                  font-weight: 400;
                }
              }
              .smallFont {
                font-size: 24px;
              }
              .grayFont {
                font-size: 20px;
                color: #999999;
              }
            }
          }
        }
        .numRow {
          display: flex;
          margin: 20px 0;
          * {
            font-size: 22px;
          }
          .normalOpt {
            padding: 0 18px;
            flex: 1;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
          }
          .productTag {
            width: 630px;
            min-height: 56px;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            position: relative;
            display: flex;
            align-items: center;
            padding: 12px 20px;
            img {
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
              width: 12px;
              height: 7px;
            }
            .addIconimg {
              width: 20px;
              height: 20px;
            }
            p {
              font-size: 22px;
              line-height: 30px;
              font-weight: 400;
              color: #000000;
              width: 630-40px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              word-break: break-all;
            }
            .placeFont {
              color: #999999;
            }
          }
          .greenOpt {
            height: 80px;
            border-color: #50c136;
            background-color: #f0ffec;
            justify-content: space-between;
            padding: 12px 12px 12px 19px;
            span {
              font-size: 24px;
            }
            input {
              width: 122px;
              height: 56px;
              background: #ffffff;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              display: inline-block;
            }
          }

          .theNumberOfLibraries {
            flex: 0 0 300px;
            &:first-child {
              margin-right: auto;
            }
          }
        }
        .remarkBox {
          width: 630px;
          height: 56px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 22px;
          line-height: 54px;
          padding: 0 19px;
        }
      }
    }
  }
}
</style>
