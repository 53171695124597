<!-- 配送单详情优惠券使用弹窗模块 -->
<template>
  <div>
    <van-action-sheet
      v-model:show="show"
      :title="$fanyi('选择优惠券')"
      class="couponUsageModal"
      @close="closeCoupon"
    >
      <div class="couponList">
        <div
          class="singleCouponP"
          v-for="(singleCouponPItem, singleCouponPIndex) in couponList"
          :key="singleCouponPIndex"
          @click="selectingCoupons(singleCouponPItem, singleCouponPIndex)"
          :class="{ activeCoupon: activeCoupon == singleCouponPIndex }"
        >
          <!-- 被用户选中的标志 -->
          <div class="selected">
            <img :src="require('@/assets/icon/gouBai.png')" alt="" />
          </div>
          <div class="couponInfo">
            <div class="other">
              <div class="couponType">{{ singleCouponPItem.type_name }}</div>
              <div class="couponCode">NO.{{ singleCouponPItem.sn }}</div>
            </div>
            <div
              class="couponName"
              v-if="singleCouponPItem.type_name != '折扣券'"
            >
              {{
                singleCouponPItem.type_name == "代金券"
                  ? singleCouponPItem.discounts_amount_jpy
                  : singleCouponPItem.name_translation
              }}
              <span>円</span>
            </div>
            <div class="couponName" v-else>
              <!-- {{ Math.round(100 - singleCouponPItem.discounts_rate) }}%
              <span>{{ $fanyi("减免") }}</span> -->
              {{
                singleCouponPItem.discounts_amount_jpy &&
                singleCouponPItem.discounts_amount_jpy != "0"
                  ? singleCouponPItem.discounts_amount_jpy
                  : Math.round(100 - singleCouponPItem.discounts_rate) + "%"
              }}
              <!-- {{ Math.round(100 - singleCouponPItem.discounts_rate) }}% -->
              <span>{{
                singleCouponPItem.discounts_amount_jpy &&
                singleCouponPItem.discounts_amount_jpy != "0"
                  ? "円"
                  : $fanyi("减免")
              }}</span>
            </div>
          </div>
          <!-- 使用限制 -->
          <div class="serviceConditions" v-if="singleCouponPItem.type == 3">
            <div class="conditionsItem">
              <span>{{ $fanyi("使用条件") }}：</span>
              <span
                >{{
                  singleCouponPItem.logistics_Name || $fanyi("所有物流")
                }}；</span
              >
            </div>
            <div class="conditionsItem">
              <span>{{ $fanyi("计费重量") }}：</span>
              <span>{{ singleCouponPItem.min_weight }}KG；</span>
            </div>
            <div class="conditionsItem">
              <span>{{ $fanyi("金额") }}：</span>
              <span>{{ singleCouponPItem.min_consumption_jpy }}円；</span>
            </div>
          </div>
          <hr />
          <div class="couponValidity">
            <span
              >{{ $fanyi("有效期") }}：{{
                getCurrentDayFGF({
                  time: singleCouponPItem.effective_date,
                  fgf: ".",
                })
              }}
              -
              {{
                getCurrentDayFGF({
                  time: singleCouponPItem.expiry_date,
                  fgf: ".",
                })
              }}</span
            >
            <span class="couponScope flexAllCenter">配送依頼書</span>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, onMounted } from "vue";
import { getCurrentDayFGF } from "@/utlis/date.js";
const { proxy } = getCurrentInstance();
//------------------------ data -----------------------------------------
const show = ref(false);
const couponList = ref([]);
const activeCoupon = ref(-1);
const lastActiveCoupon = ref(-1); //用户上一次选择的优惠券
//------------------------ pageLoad -------------------------------------

// 获取优惠券列表
const getCouponList = () => {
  proxy.$api
    .usableDiscount({
      pageSize: 999,
      page: 1,
      porder_sn: proxy.$route.query.pOrder_sn,
    })
    .then((res) => {
      ////console.log('事件名',res)
      if (res.code != 0) return;
      //接下来的操作
      res.data.data.forEach((dataItem) => {
        // console.log(proxy.$parent.interList);
        if (dataItem.logistics_id) {
          dataItem.logistics_id = dataItem.logistics_id.split(",");
          dataItem.logistics_Name = [];
          dataItem.logistics_id.forEach((hjk) => {
            let findName = proxy.$parent.interList.find((inIt) => {
              return inIt.id == hjk;
            });
            dataItem.logistics_Name.push((findName || {}).name);
          });
          dataItem.logistics_Name = dataItem.logistics_Name.join(",");
        }
        if (dataItem.type == 3) {
          if (
            dataItem.discounts_rate != 0 &&
            dataItem.discounts_rate % 10 == 0
          ) {
            dataItem.discounts_rate_font = (
              dataItem.discounts_rate + ""
            ).substr(0, 1);
          }
        }
      });
      couponList.value = res.data.data;
      proxy.$parent.couponList = res.data.data;
    });
};
//------------------------ methods --------------------------------------
// 选择优惠券
const selectingCoupons = (item, i) => {
  if (activeCoupon.value == i) {
    activeCoupon.value = -1;
    proxy.$parent.hasUsedCoupon = false;
    proxy.$parent.usedCouponInfo = { discounts_amount_jpy: 0 };
  } else {
    activeCoupon.value = i;
    proxy.$parent.hasUsedCoupon = true;
    proxy.$parent.porder_amount.discounts_amount_jpy =
      item.discounts_amount_jpy;
    proxy.$parent.usedCouponInfo = JSON.parse(JSON.stringify(item));
    // 重新计算
  }
  proxy.$parent.commodityTotal();
};
// 关闭弹窗事件
const closeCoupon = () => {
  // console.log("关闭");
  // 在关闭弹窗时调用支付界面接口的数据来计算优惠金额
  if (lastActiveCoupon.value != activeCoupon.value) {
    proxy.$api
      .storagePOrderPayment({
        porder_sn: proxy.$route.query.pOrder_sn,
        discounts_id:
          activeCoupon.value != -1
            ? couponList.value[activeCoupon.value].id
            : [],
      })
      .then((res) => {
        // proxy.$parent.usedCouponInfo.discounts_amount_jpy =
        //   res.data.discounts_amount_jpy;
        proxy.$parent.porder_amount.sum_amount = proxy.$fun.ceil(
          res.data.pay_amount_jpy / res.data.exchange_rate
        );
        proxy.$parent.porder_amount.pay_amount_jpy = res.data.pay_amount_jpy;
        lastActiveCoupon.value = activeCoupon.value;
      });
  }
};
onMounted(() => {
  getCouponList();
});
const open = () => {
  show.value = true;
};
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";

.couponList {
  min-height: 700px;
  padding: 0 30px 120px;

  .singleCouponP {
    width: 690px;
    min-height: 200px;
    background: #ffffff;
    border: 1px solid #b4272d;
    border-radius: 20px;
    margin-bottom: 15px;
    padding: 35px;
    position: relative;

    .couponInfo {
      display: flex;

      align-items: flex-start;
      margin-bottom: 13px;

      .other {
        width: 297px;
        margin-right: 25px;

        .couponType {
          font-size: 32px;
          font-weight: bold;
          color: #010101;
          line-height: 1;
          margin-bottom: 15px;
        }

        .couponCode {
          font-size: 24px;
          font-weight: 400;
          color: #666666;
        }
      }

      .couponName {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-size: 48px;
        font-weight: bold;
        color: #b4272d;
        min-width: 210px;
        text-align: right;
        span {
          font-size: 22px;
        }
      }
    }
    .serviceConditions {
      display: flex;
      flex-wrap: wrap;
      .conditionsItem {
        span {
          font-size: 23px;
          line-height: 28px;
        }
      }
    }
    hr {
      height: 0;
      background-color: transparent;
      border: none;
      margin-top: 20px;
      border-bottom: 3px dashed #dfdfdf;
    }
    .selected {
      right: 0;
      top: 0;
      position: absolute;
      display: none;
      justify-content: center;
      align-items: center;
      width: 50px;
      height: 50px;
      background: #b4272d;
      border-radius: 0px 20px 0px 20px;

      img {
        width: 19px;
        height: 14px;
      }
    }

    .couponValidity {
      display: flex;
      align-items: center;

      span {
        font-size: 24px;
        color: #999999;
      }

      .couponScope {
        margin-left: 24px;
        width: 140px;
        height: 32px;
        background: #ffe9e9;
        border-radius: 6px;
        font-size: 20px;
        color: #b4272d;
      }
    }
  }

  .singleCouponP.activeCoupon {
    .selected {
      display: flex;
    }
  }
}
</style>
<style lang="scss">
.couponUsageModal {
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
  }
}
</style>
