<!-- 报价中配送单列表 -->
<template>
  <div class="tablePosiBox">
    <ul class="gratisTableBox">
      <li v-for="(tableItem, tableIndex) in tableData" :key="tableIndex">
        <h2>
          <span>{{ $fanyi("订单号") }}：{{ tableItem.order_sn }}</span>
          <span>
            <img :src="require('@/assets/icon/dingdanfanhao.png')" alt="" />
            番号：#{{ tableItem.sorting }}
          </span>
        </h2>
        <div class="goodsList">
          <div class="productInformation">
            <input
              type="checkbox"
              v-if="['delete'].includes($parent.userOperationMode)"
              :checked="tableItem.checked"
              @click="
                tableItem.checked = !tableItem.checked;
                $parent.oddChoice(tableIndex);
              "
            />
            <van-image
              lazy-load
              class="productImg"
              :src="tableItem.pic"
              @click="
                $fun.toCommodityDetails(
                  tableItem.goods_id,
                  tableItem.from_platform
                )
              "
            >
              <template v-slot:loading>
                <van-loading type="spinner" size="20" />
              </template>
            </van-image>
            <div class="infoBox">
              <h3>{{ tableItem.goods_title }}</h3>
              <div class="detailBox" @click="openDetailBox(tableItem)">
                <span
                  v-for="(
                    order_detaildetailitem, order_detaildetailindex
                  ) in tableItem.detail"
                  :key="order_detaildetailindex"
                >
                  {{ order_detaildetailitem.value }};
                </span>
              </div>
              <div class="priceBox">
                <span class="redFont">
                  <span class="bigFont">
                    {{ $fun.ceil(tableItem.confirm_price) }}
                    {{ $fanyi("元") }}
                  </span>
                  <span class="smallFont">
                    ({{
                      $fun.JPYNumSegmentation(
                        $fun.roundNumber(
                          tableItem.confirm_price *
                            $parent.deliveryOrderExchangeRate
                        )
                      )
                    }}
                    円 )
                  </span>
                </span>
                <span class="grayFont">X {{ tableItem.confirm_num }} </span>
              </div>
            </div>
          </div>
          <div class="numRow">
            <div class="normalOpt greenOpt">
              <span>{{ $fanyi("提货数量") }}:{{ tableItem.send_num }}</span>
            </div>
          </div>
          <!-- 装箱信息 -->
          <div
            class="numRow"
            v-if="tableItem.in_porder_box && tableItem.in_porder_box.length"
          >
            <div class="packingInformation">
              <div
                class="packingInformationOpt"
                v-for="(boxItem, boxIndex) in tableItem.in_porder_box"
                :key="boxIndex"
              >
                <div class="numTag">{{ boxItem.box_number }}</div>
                <span class="henxian">----------</span>
                <span>{{ boxItem.in_box_num }}个</span>
              </div>
            </div>
          </div>
          <div
            class="numRow"
            v-for="(tagItem, tagIndex) in tableItem.porder_detail_tag"
            :key="tagIndex"
          >
            <div class="productTag">
              <div>
                <p v-show="tagItem.type">
                  {{ tagItem.type }}
                </p>
                <p v-show="tagItem.no">
                  {{ tagItem.no }}
                </p>
                <p v-show="tagItem.goods_no">
                  {{ tagItem.goods_no }}
                </p>
              </div>
            </div>
          </div>
          <div class="remarkBox" v-if="tableItem.client_remark">
            {{ tableItem.client_remark }}
          </div>
        </div>
      </li>
    </ul>
    <editProductTag ref="editProductTagRef" />
    <detailsAllShowVue ref="detailsAllShowVueRef" />
  </div>
</template>
<script setup>
import detailsAllShowVue from "@/components/detailsAllShow";
import editProductTag from "./editProductTag.vue";
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data -------------------------------------
const tableData = computed(() => {
  return proxy.$parent.tableData;
});
// 提货数量限制
const pickupQuantityLimit = (data) => {
  if (data.send_num > data.send_await_num) {
    data.send_num = data.send_await_num;
    return proxy.$message.warning(proxy.$fanyi("提货数量不能超过可提货数量"));
  }
};
const userOperationMode = computed(() => {
  return proxy.$parent.userOperationMode;
});
//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
// 打开商品标签编辑弹窗
const openProductTagEditorPopup = (data) => {
  proxy.$refs.editProductTagRef.open(data);
};
// 打开商品详情弹窗
const openDetailBox = (data) => {
  // 将需要的数据传入
  proxy.$refs.detailsAllShowVueRef.open({
    goods_title: data.goods_title,
    detail: data.detail,
    pic: data.pic,
    sort: data.sorting,
  });
};
defineExpose({ userOperationMode });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.tablePosiBox {
  min-height: 70vh;

  position: relative;
  .gratisTableBox {
    margin: 0 auto;
    width: 690px;
    li {
      margin: 20px 0 1px;
      padding: 0 30px 30px;
      background-color: white;
      overflow: hidden;
      &.openrowd {
        max-height: unset;
      }
      h2 {
        height: 100px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        display: flex;
        justify-content: space-between;
        img {
          width: 26px;
          height: 26px;
          margin-right: 9px;
        }
        .van-icon-arrow-down {
          margin-left: auto;
          transition: 0.3s;
          font-size: 30px;
        }
        .van-icon-arrow-down.Collapse {
          rotate: -180deg;
        }
        span {
          font-size: 28px;
          font-weight: 500;
          display: flex;
          justify-content: center;
          align-items: center;
        }
      }
      // 订单内商品
      .goodsList {
        padding-bottom: 1px;
        margin-bottom: 30px;
        border-bottom: dashed 1px #dfdfdf;
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
        .outfit {
          display: flex;
          align-items: center;
          height: 42px;

          margin-bottom: 20px;
          span {
            font-size: 28px;
          }
        }
        .productInformation {
          display: flex;
          align-items: center;
          margin-bottom: 30px;
          input[type="checkbox"] {
            width: 30px;
            flex: 0 0 30px;
            margin-right: 20px;
            height: 30px;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
          }
          .productImg {
            $size: 180px;
            flex: 0 0 $size;
            width: $size;
            height: $size;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 20px;
          }
          .infoBox {
            h3 {
              font-size: 28px;
              line-height: 42px;
              height: 84px;
              margin-bottom: 5px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .detailBox {
              width: 350px;
              height: 42px;
              line-height: 39px;
              background: #fafafa;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 10px;
              margin-bottom: 10px;
              line-height: 40px;
              font-size: 22px;
              span {
                line-height: 40px;
                font-size: 22px;
                color: #999999;
              }
            }
            .priceBox {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .redFont {
                color: #b4272b;
              }
              .bigFont {
                font-size: 28px;
                font-weight: 600;
              }
              .smallFont {
                font-size: 24px;
              }
              .grayFont {
                font-size: 20px;
                color: #999999;
              }
            }
          }
        }
        .numRow {
          display: flex;
          margin: 20px 0;
          * {
            font-size: 22px;
          }
          .normalOpt {
            padding: 0 18px;
            flex: 1;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
          }
          .productTag {
            width: 630px;
            min-height: 56px;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            position: relative;
            display: flex;
            align-items: center;
            padding: 12px 20px;
            img {
              position: absolute;
              right: 30px;
              top: 50%;
              transform: translateY(-50%);
              width: 12px;
              height: 7px;
            }
            p {
              font-size: 22px;
              line-height: 30px;
              font-weight: 400;
              color: #000000;
            }
            .placeFont {
              color: #999999;
            }
          }
          .packingInformation {
            width: 630px;
            height: 114px;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
            padding: 12px 20px;
            overflow: auto;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-between;
            align-items: flex-start;
            .packingInformationOpt {
              width: 280px;
              display: flex;
              align-items: center;
              line-height: 30px;
              margin-bottom: 5px;
              .numTag {
                width: 22px;
                height: 22px;
                border-radius: 50%;
                border: solid 1px #000;
                display: flex;
                justify-content: center;
                align-items: center;
              }
              .henxian {
                transform: translateY(-3px);
                margin: 0 10px;
              }
            }
          }
          .greenOpt {
            height: 56px;
            border-color: #50c136;
            background-color: #f0ffec;
            justify-content: space-between;
            padding: 12px 12px 12px 19px;
            span {
              font-size: 24px;
            }
            input {
              width: 122px;
              height: 56px;
              background: #ffffff;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              display: inline-block;
            }
          }

          .theNumberOfLibraries {
            flex: 0 0 300px;
            &:first-child {
              margin-right: auto;
            }
          }
        }
        .remarkBox {
          width: 630px;
          min-height: 56px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 22px;
          line-height: 32px;
          padding: 12px 19px;
        }
      }
    }
  }
}
</style>
