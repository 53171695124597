<!-- 配送单追加商品模块 -->
<template>
  <van-action-sheet v-model:show="show" :title="$fanyi('追加商品')">
    <div class="whoseHousPage">
      <div>
        <div class="tablePosiBox">
          <ul class="gratisTableBox">
            <li
              v-for="(gratisItem, gratisIndex) in gratisTable"
              :key="gratisIndex"
              :class="{
                openrowd: gratisItem.Collapse,
              }"
            >
              <h2 @click="gratisItem.Collapse = !gratisItem.Collapse">
                <input
                  type="checkbox"
                  :checked="gratisItem.checked"
                  @click.stop="
                    gratisItem.checked = !gratisItem.checked;
                    shopAll(gratisIndex);
                  "
                />
                <span>{{ $fanyi("订单号") }}：{{ gratisItem.order_sn }}</span>
                <van-icon
                  name="arrow-down"
                  :class="{ Collapse: gratisItem.Collapse }"
                />
              </h2>
              <div
                class="goodsList"
                v-for="(
                  order_detailitem, order_detailindex
                ) in gratisItem.order_detail"
                :key="order_detailindex"
              >
                <div class="outfit">
                  <input
                    type="checkbox"
                    :checked="order_detailitem.checked"
                    @click="
                      order_detailitem.checked = !order_detailitem.checked;
                      oddChoice(gratisIndex);
                    "
                  />
                  <span>番号：#{{ order_detailindex + 1 }}</span>
                </div>
                <div class="productInformation">
                  <van-image
                    lazy-load
                    class="productImg"
                    :src="order_detailitem.pic"
                    @click="
                      $fun.toCommodityDetails(
                        order_detailitem.goods_id,
                        order_detailitem.from_platform
                      )
                    "
                  >
                    <template v-slot:loading>
                      <van-loading type="spinner" size="20" />
                    </template>
                  </van-image>
                  <div class="infoBox">
                    <h3>{{ order_detailitem.goods_title }}</h3>
                    <div
                      class="detailBox"
                      @click="openDetailBox(order_detailitem)"
                    >
                      <span
                        v-for="(
                          order_detaildetailitem, order_detaildetailindex
                        ) in order_detailitem.detail"
                        :key="order_detaildetailindex"
                      >
                        {{ order_detaildetailitem.value }};
                      </span>
                    </div>
                    <div class="priceBox">
                      <span class="redFont">
                        <span class="bigFont">
                          {{ $fun.ceil(order_detailitem.confirm_price) }}
                          {{ $fanyi("元") }}
                        </span>

                        <span class="smallFont">
                          ({{
                            $fun.JPYNumSegmentation(
                              $fun.roundNumber(
                                order_detailitem.confirm_price *
                                  $store.state.exchangeRate
                              )
                            )
                          }}
                          円 )
                        </span>
                      </span>
                      <span class="grayFont"
                        >X {{ order_detailitem.confirm_num }}
                      </span>
                    </div>
                  </div>
                </div>
                <div class="numRow">
                  <div class="theNumberOfLibraries normalOpt">
                    {{ $fanyi("在rakumart库数量") }}:{{
                      order_detailitem.is_rakumart_stcok_num
                    }}
                  </div>
                  <div class="theNumberOfLibraries normalOpt">
                    {{ $fanyi("已提出数") }}:{{
                      order_detailitem.send_success_numjsidfj
                    }}
                  </div>
                </div>
                <div class="numRow">
                  <div class="normalOpt greenOpt">
                    {{ $fanyi("发送可能数") }}:{{
                      order_detailitem.send_await_num
                    }}
                  </div>
                </div>
                <div class="numRow">
                  <div
                    class="normalOpt optionBox"
                    @click="openOptionBox(order_detailitem.option)"
                  >
                    <span
                      class="optionOpt"
                      v-for="(
                        order_detailoptionitem, order_detailoptionindex
                      ) in order_detailitem.option"
                      :key="order_detailoptionindex"
                    >
                      <span class="tagNum">
                        {{ order_detailoptionitem.sort + 1 }}</span
                      >
                      *{{ order_detailoptionitem.num }}，
                    </span>
                  </div>
                </div>
                <div class="remarkBox">
                  {{ order_detailitem.client_remark }}
                </div>
              </div>
            </li>
          </ul>
        </div>
        <detailsAllShowVue ref="detailsAllShowVueRef" />
        <optionsAllShowVue ref="optionsAllShowVueRef" />
      </div>
      <div class="footBarBox">
        <input type="checkbox" :checked="checked" @click="allChoice()" />
        <span class="quanxuan" @click="allChoice()">{{ $fanyi("全选") }}</span>
        <span>{{ $fanyi("已勾选番数") }}:</span>
        <span class="num">{{ commodityTotalData.fanshu }}</span>
        <button @click="additionOfGoods">{{ $fanyi("追加到配送单") }}</button>
      </div>
    </div>
  </van-action-sheet>
</template>
<script>
import { ref, getCurrentInstance, onMounted, toRef, watch } from "vue";
import { SwipeInstance } from "vant";
import headBackBox from "../../../components/headGoBack";
import tabToggleVue from "../../../components/tabToggle";
import detailsAllShowVue from "../../../components/detailsAllShow";
import optionsAllShowVue from "../../../components/optionsAllShow";
export default {
  setup(props) {
    const show = ref(false);
    const { proxy } = getCurrentInstance();
    // 根据路由切换仓库
    const type = ref("stockAutoList");
    // 免费仓库数据
    const gratisTable = ref(null);
    // 付费仓库数据
    const payTable = ref([]);
    // 免费仓库是否全选
    const checked = ref(false);
    const swiperModel = ref(0);
    const detailsAllShowVueRef = ref(null);
    const optionsAllShowVueRef = ref(null);
    const swipeRef = ref(null);

    const navList = ref([
      {
        label: "普通仓库",
        value: "0",
      },
      {
        label: "收费仓库",
        value: "1",
      },
    ]);

    const commodityTotalData = ref({
      // 已勾选番数
      fanshu: 0,
      // 已勾选商品id数组
      selectedProductId: [],
    });
    // 统计各种数据
    const commodityTotal = () => {
      commodityTotalData.value = {
        fanshu: 0,
        selectedProductId: [],
      };
      gratisTable.value.forEach((item, index) => {
        item.order_detail.forEach((detailItem) => {
          // detailItem-单个商品
          if (detailItem.checked) {
            commodityTotalData.value.fanshu += 1;
            commodityTotalData.value.selectedProductId.push(detailItem.id);
          }
        });
      });
    };
    const open = (data) => {
      // 获取数据
      proxy.$api.porderPreviewAddBeStockAuto(data).then((res) => {
        ////console.log('事件名',res)
        if (res.code != 0) return;
        //接下来的操作
        gratisTable.value = res.data;
        show.value = true;
        commodityTotal();
      });
    };

    // 免费仓库单选
    const oddChoice = (i) => {
      let flag = gratisTable.value[i].order_detail.every((item) => {
        return item.checked == true;
      });
      if (flag) {
        gratisTable.value[i].checked = true;
      } else {
        gratisTable.value[i].checked = false;
      }
      isCheckedAll();
      commodityTotal();
      //   flag ? (gratisTable.value[i].checked = true) : (gratisTable.value[i].checked = false);
    };
    // 免费仓库订单全选
    const shopAll = (i) => {
      if (gratisTable.value[i].checked) {
        gratisTable.value[i].order_detail.forEach((item) => {
          item.checked = true;
        });
      } else {
        gratisTable.value[i].order_detail.forEach((item) => {
          item.checked = false;
        });
      }
      isCheckedAll();
      commodityTotal();
    };
    // 免费仓库全选
    const allChoice = () => {
      checked.value = !checked.value;
      if (checked.value) {
        gratisTable.value.forEach((item, index) => {
          item.checked = true;
          shopAll(index);
        });
      } else {
        gratisTable.value.forEach((item, index) => {
          item.checked = false;
          shopAll(index);
        });
      }
      commodityTotal();
    };
    // 免费仓库是否全选中
    const isCheckedAll = () => {
      var flag = gratisTable.value.every((item, index) => item.checked == true);
      if (flag == true) {
        checked.value = true;
      } else {
        checked.value = false;
      }
    };
    // 类型切换事件
    const onChange = (index) => {
      swiperModel.value = index;
    };
    // 打开商品详情弹窗
    const openDetailBox = (data) => {
      detailsAllShowVueRef.value.open({
        goods_title: data.goods_title,
        detail: data.detail,
        pic: data.pic,
      });
    };
    // 打开附加服务弹窗
    const openOptionBox = (data) => {
      optionsAllShowVueRef.value.open(data);
    };
    // 追加商品
    const additionOfGoods = () => {
      let appendData = [];
      gratisTable.value.forEach((item, index) => {
        item.order_detail.forEach((detailItem) => {
          // detailItem-单个商品
          if (detailItem.checked) {
            appendData.push(detailItem);
          }
        });
      });
      proxy.$parent.receiveAdditionalData(appendData);
      show.value = false;
    };
    return {
      type,
      gratisTable,
      payTable,
      checked,
      detailsAllShowVueRef,
      optionsAllShowVueRef,
      commodityTotalData,
      swiperModel,
      navList,
      onChange,
      openDetailBox,
      openOptionBox,
      oddChoice,
      shopAll,
      allChoice,
      isCheckedAll,
      commodityTotal,
      additionOfGoods,
      open,
      show,
    };
  },
  methods: {},
  components: {
    headBackBox,
    tabToggleVue,
    detailsAllShowVue,
    optionsAllShowVue,
  },
};
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.whoseHousPage {
  background-color: #f6f6f6;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  min-height: 900px;
}
input[type="checkbox"] {
  flex: 0 0 30px;
  width: 30px;
  height: 30px;
  margin: 0;
  margin-top: 4px;
  margin-right: 20px;
  border-color: #dfdfdf;
}

.tablePosiBox {
  //   min-height: 70vh;

  position: relative;
  .gratisTableBox {
    margin: 0 auto;
    width: 690px;
    li {
      margin: 20px 0 1px;
      padding: 0 30px;
      background-color: white;
      max-height: 100px;
      overflow: hidden;
      &.openrowd {
        max-height: unset;
      }
      h2 {
        height: 100px;
        display: flex;
        align-items: center;
        margin-bottom: 5px;
        .van-icon-arrow-down {
          margin-left: auto;
          transition: 0.3s;
          font-size: 30px;
        }
        .van-icon-arrow-down.Collapse {
          rotate: -180deg;
        }
        span {
          font-size: 28px;
          font-weight: 500;
        }
      }
      // 订单内商品
      .goodsList {
        padding-bottom: 1px;
        margin-bottom: 30px;
        border-bottom: dashed 1px #dfdfdf;
        &:last-child {
          margin-bottom: 0;
          border-bottom: none;
        }
        .outfit {
          display: flex;
          align-items: center;
          height: 42px;

          margin-bottom: 20px;
          span {
            font-size: 28px;
          }
        }
        .productInformation {
          display: flex;
          margin-bottom: 30px;
          .productImg {
            $size: 180px;
            flex: 0 0 $size;
            width: $size;
            height: $size;
            border-radius: 6px;
            overflow: hidden;
            margin-right: 20px;
          }
          .infoBox {
            h3 {
              font-size: 28px;
              line-height: 42px;
              margin-bottom: 5px;
              text-overflow: -o-ellipsis-lastline;
              overflow: hidden;
              text-overflow: ellipsis;
              display: -webkit-box;
              -webkit-line-clamp: 2;
              -webkit-box-orient: vertical;
            }
            .detailBox {
              width: 350px;
              height: 42px;
              line-height: 39px;
              background: #fafafa;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              overflow: hidden;
              text-overflow: ellipsis;
              white-space: nowrap;
              padding: 0 10px;
              margin-bottom: 10px;
              display: inline-flex;
              span {
                font-size: 22px;
                color: #999999;
              }
            }
            .priceBox {
              display: flex;
              justify-content: space-between;
              align-items: center;
              .redFont {
                color: #b4272b;
              }
              .bigFont {
                font-size: 28px;
                font-weight: 600;
                .secBig {
                  font-size: 24px;
                  font-weight: 400;
                }
              }
              .smallFont {
                font-size: 24px;
              }
              .grayFont {
                font-size: 20px;
                color: #999999;
              }
            }
          }
        }
        .numRow {
          display: flex;
          margin: 20px 0;
          * {
            font-size: 22px;
          }
          .normalOpt {
            padding: 0 18px;
            flex: 1;
            height: 56px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #fafafa;
            border: 1px solid #dfdfdf;
            border-radius: 6px;
          }
          .greenOpt {
            border-color: #50c136;
            color: #50c136;
            background-color: #f0ffec;
          }
          .optionBox {
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
            display: inline-block;

            .optionOpt {
              line-height: 56px;
              .tagNum {
                border: 1px solid #000000;
                border-radius: 50%;
                $size: 22px;
                width: $size;
                height: $size;
                display: inline-block;
                text-align: center;
                line-height: 18px;
              }
            }
          }
          .theNumberOfLibraries {
            flex: 0 0 300px;
            &:first-child {
              margin-right: auto;
            }
          }
        }
        .remarkBox {
          min-height: 56px;
          padding: 15px;
          background: #fafafa;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          font-size: 22px;
          margin-bottom: 30px;
        }
      }
    }
  }
}
.tablePayBg {
  min-height: 70vh;
}
.tablePayBox {
  width: 690px;
  // height: 232px;
  background: #ffffff;
  border-radius: 6px;
  padding: 30px;
  margin: 20px auto;
  h2 {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    .boxNumber {
      width: 160px;
      height: 60px;
      background: #f0ffec;
      border: 1px solid #50c136;
      border-radius: 6px;
      color: #50c136;
      font-size: 28px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
    .linkIcon {
      transform: translateX(30px);
      padding: 0 29px;
      height: 60px;
      display: flex;
      justify-content: center;
      align-items: center;
      :deep(.van-icon-arrow) {
        font-size: 25px;
      }
    }
  }
  .parameter {
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    .parameterOpt {
      margin-bottom: 20px;
      &:last-child,
      &:nth-last-child(2) {
        margin-bottom: 0;
      }
      flex: 0 0 300px;
      line-height: 36px;
      span {
        font-size: 24px;
      }
      .otitle {
        color: #999999;
      }
      .onum {
        color: #000000;
      }
      .redFont {
        color: #b4272b;
      }
    }
  }
}
.footBarBox {
  display: flex;
  align-items: center;
  position: sticky;
  width: 100%;
  height: 108px;
  background-color: white;
  bottom: 0;
  padding: 15px 30px;
  * {
    font-size: 24px;
  }
  input[type="checkbox"] {
    margin-right: 10px;
  }
  .quanxuan {
    margin-right: 30px;
  }
  .num {
    font-size: 28px;
    color: #b4272b;
  }
  button {
    margin-left: auto;
    width: 240px;
    height: 70px;
    background: #b4272b;
    border-radius: 6px;
    color: #ffffff;
    font-size: 24px;
  }
}
.shippingTips {
  margin: 20px auto;
  width: 690px;
  height: 68px;
  background: #ffe9e9;
  border-radius: 6px;
  padding: 16px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  img {
    margin-right: 11px;
    $size: 30px;
    width: $size;
    height: $size;
  }
  span {
    color: #b4272b;
    font-size: 24px;
  }
}
</style>
