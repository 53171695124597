<!--商品标签编辑模块 配送单详情使用 -->
<template>
  <div>
    <van-action-sheet v-model:show="show" :title="$fanyi('商品标签编辑')">
      <div class="modelPage">
        <div class="goodsSnBox">
          <span>{{ $fanyi("订单号") }}：{{ detailData.order_sn }}</span>
          <span>
            <img :src="require('@/assets/icon/dingdanfanhao.png')" alt="" />
            番号：#{{ detailData.sorting }}
          </span>
        </div>
        <div class="goodsInfo">
          <van-image
            lazy-load
            class="goodsImg"
            :src="detailData.pic"
            @click="
              $fun.toCommodityDetails(
                detailData.goods_id,
                detailData.from_platform
              )
            "
          >
            <template v-slot:loading>
              <van-loading type="spinner" size="20" />
            </template>
          </van-image>
          <div class="goodsTitle">
            {{ detailData.goods_title }}
          </div>
        </div>
        <div class="TagSelectionForm">
          <div class="row">
            <h4>{{ $fanyi("仓库类别") }}：</h4>
            <van-dropdown-menu direction="up" class="xialakuang">
              <van-dropdown-item
                v-model="tagData.type"
                :options="tagTypeList"
                @change="selectTr"
              />
            </van-dropdown-menu>
          </div>
          <div class="row">
            <h4>{{ $fanyi("标签编码") }}：</h4>
            <input
              type="text"
              v-model.trim="tagData.no"
              :placeholder="$fanyi('请输入标签号')"
            />
          </div>
          <div class="row">
            <h4>{{ $fanyi("商品管理编码") }}：</h4>
            <input
              type="text"
              v-model.trim="tagData.goods_no"
              :placeholder="$fanyi('请输入商品码')"
            />
          </div>
          <div class="btnList">
            <button class="successBtn" @click="saveTagData">
              {{ $fanyi("保存") }}
            </button>
            <button
              class="cancelBtn"
              @click="cancelTagData"
              v-if="tagdataIndex != null"
            >
              {{ $fanyi("删除") }}
            </button>
          </div>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
const show = ref(false);
const detailData = ref({});
const tagTypeList = ref([]); //标签类型列表
const tagdataIndex = ref(null); //标签的下标,删除时使用
const tagData = ref({
  type: "FBA",
  no: "",
  goods_no: "",
}); //标签数据
// 单个商品的标签列表
const tagList = ref([]);
// 打开弹窗和初始化数据
const open = (data, tagIndex) => {
  console.log(data);
  show.value = true;
  tagList.value = JSON.parse(JSON.stringify(data.porder_detail_tag || []));
  if (tagIndex != undefined) {
    tagData.value = tagList.value[tagIndex];
    // 如果是修改数据就保存下标
    tagdataIndex.value = tagIndex;
  } else {
    tagdataIndex.value = null;
    tagData.value = {
      type: "FBA",
      type_translate: "アマゾン倉庫",
      no: "",
      goods_no: "",
    };
    tagList.value.push(tagData.value);
  }
  detailData.value = data;
};
// 获取标签类型列表
const getOrderTagList = () => {
  proxy.$api.getOrderTagList().then((res) => {
    if (res.code != 0) return;
    //接下来的操作
    tagTypeList.value = res.data;
    tagTypeList.value.forEach((tagTypeListItem) => {
      tagTypeListItem.text = tagTypeListItem.type_translate;
      tagTypeListItem.value = tagTypeListItem.type;
    });
  });
};
const selectTr = (v) => {
  tagData.value.type_translate = tagTypeList.value.find((item) => {
    return item.type == v;
  }).type_translate;
};

// 保存标签数据
const saveTagData = () => {
  if (!tagData.value.no && !tagData.value.goods_no) {
    // detailData.value.porder_detail_tag = null;
  } else {
    detailData.value.porder_detail_tag = tagList.value;
  }

  show.value = false;
};
// 删除标签数据
const cancelTagData = () => {
  // 如果有tagdataIndex说明是修改数据,就执行删除操作
  if (tagdataIndex.value != null) {
    tagList.value.splice(tagdataIndex.value, 1);
    detailData.value.porder_detail_tag = tagList.value;
  }
  show.value = false;
};
getOrderTagList();
defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.modelPage {
  margin: 0 30px;
  .goodsSnBox {
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    img {
      width: 26px;
      height: 26px;
      margin-right: 9px;
    }
    span {
      font-size: 28px;
      font-weight: 500;
      color: #000000;
      display: flex;
      align-items: center;
    }
  }
  .goodsInfo {
    display: flex;
    padding-bottom: 40px;
    .goodsImg {
      $size: 120px;
      flex: 0 0 $size;
      width: $size;
      height: $size;
      margin-right: 20px;
    }
    .goodsTitle {
      text-overflow: -o-ellipsis-lastline;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 3;
      -webkit-box-orient: vertical;
      font-size: 28px;
      line-height: 42px;
    }
  }
}
.TagSelectionForm {
  .row {
    margin-bottom: 30px;
    h4 {
      line-height: 42px;
      font-size: 28px;
      font-weight: 400;
      color: #000000;
      margin-bottom: 10px;
    }
    .van-dropdown-menu,
    :deep(.van-dropdown-menu) {
      .van-dropdown-menu__bar {
        width: 690px;
        height: 64px;
        background: #ffffff;
        border: 1px solid #dfdfdf;
        border-radius: 6px;
        box-shadow: none;

        .van-dropdown-menu__title {
          width: 100%;
          padding-left: 29px;

          .van-ellipsis {
            font-size: 24px;
            // color: #b4272b;
          }

          &:after {
            right: 13px;
            color: #b4272b;
          }
        }

        .van-dropdown-menu__title--active {
          color: #000000;
        }
      }
    }
    input[type="text"] {
      width: 690px;
      height: 64px;
      background: #ffffff;
      border: 1px solid #dfdfdf;
      border-radius: 6px;
      font-size: 24px;
      padding: 0 30px;
    }
  }
}
.btnList {
  display: flex;
  button {
    width: 690px;
    height: 70px;
    margin-top: 72px;
    margin-bottom: 30px;
    border-radius: 6px;
    font-size: 28px;
  }
  .successBtn {
    background: #b4272b;
    color: #ffffff;
  }
  .cancelBtn {
    margin-left: 20px;
  }
}
</style>
<style lang="scss">
.orderDetailPopup {
  .van-action-sheet__header {
    text-align: center !important;
    font-size: 30px;
  }
}

.xialakuang {
  .van-cell__title {
    span {
      font-size: 28px;
    }
  }

  .van-dropdown-item__option--active {
    .van-cell__title {
      span {
        font-size: 28px;
        color: #b4272b;
      }
    }

    .van-dropdown-item__icon {
      color: #b4272b;
    }
  }
}
</style>
