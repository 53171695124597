<!-- 配送单详情用户操作栏模块 -->
<template>
  <div class="cartBottom">
    <!-- 操作栏 -->
    <div class="openOrderDetailModal" @click="$parent.opendeliveryAddress()">
      <span v-if="['临时保存'].includes($parent.porder_status_name)">{{
        $fanyi("配送信息填写（配送方式，订单备注）")
      }}</span>
      <span v-else>{{ $fanyi("配送信息") }}</span>
      <img src="../../../assets/icon/arrow-downskdjfh.png" alt="" />
    </div>
    <!-- <div
      v-if="!['临时保存', '装箱中'].includes($parent.porder_status_name)"
      class="openOrderDetailModal"
      @click="$parent.translateCostDetailsToCamelCase()"
    >
      <span style="color: black">{{ $fanyi("费用明细") }}</span>
      <img :src="require('@/assets/icon/arrow-downskdjfh.png')" alt="" />
    </div> -->
    <div
      v-if="!['临时保存', '装箱中'].includes($parent.porder_status_name)"
      class="openOrderDetailModal"
      @click="$parent.openBoxDetail()"
    >
      <span style="color: black">{{ $fanyi("装箱信息") }}</span>
      <img :src="require('@/assets/icon/arrow-downskdjfh.png')" alt="" />
    </div>
    <template
      v-if="
        ['临时保存'].includes($parent.porder_status_name) &&
        porder_canEdit &&
        porder_status_Num_NoChange == 30
      "
    >
      <div class="requestOrderStatus" v-if="userOperationMode == 'propose'">
        <div class="buttonGroup">
          <button class="temporarySave" @click="$parent.cancelModification">
            {{ $fanyi("取消修改") }}
          </button>
          <button @click="$parent.porderporderEdit">
            {{ $fanyi("提出") }}
          </button>
        </div>
      </div>
      <!-- 删除操作栏 -->
      <div class="con" v-else>
        <input
          type="checkbox"
          name=""
          id=""
          v-model="$parent.checked"
          @change="$parent.allChoice"
        />
        <span
          class="quanXuan"
          @click="
            $parent.checked = !$parent.checked;
            $parent.allChoice();
          "
          >{{ $fanyi("全选") }}</span
        >
        <button @click="delCart()">
          {{ $fanyi("删除") }}
        </button>
      </div>
    </template>
    <template v-else-if="['临时保存'].includes(porder_status_name)">
      <div class="requestOrderStatus" v-if="userOperationMode == 'propose'">
        <div class="buttonGroup">
          <button
            class="temporarySave"
            @click="$parent.generateDeliveryOrder('tempSave')"
          >
            {{ $fanyi("临时保存") }}
          </button>
          <button @click="$parent.generateDeliveryOrder('submit')">
            {{ $fanyi("提出") }}
          </button>
        </div>
      </div>
      <!-- 删除操作栏 -->
      <div class="con" v-else>
        <input
          type="checkbox"
          name=""
          id=""
          v-model="$parent.checked"
          @change="$parent.allChoice"
        />
        <span
          class="quanXuan"
          @click="
            $parent.checked = !$parent.checked;
            $parent.allChoice();
          "
          >{{ $fanyi("全选") }}</span
        >
        <button @click="delCart()">
          {{ $fanyi("删除") }}
        </button>
      </div>
    </template>

    <template v-if="!['临时保存', '装箱中'].includes(porder_status_name)">
      <div class="waitingForPaymentActionColumn">
        <div class="deliveryOrderPaymentInformation">
          <ul>
            <li>
              <div>
                <span class="numTitle">{{ $fanyi("sku") }}：</span>
                <span>{{ porder_amount.send_count }}</span>
              </div>
              <div>
                <span class="numTitle">{{ $fanyi("商品总数") }}：</span>
                <span>{{ porder_amount.send_num }}</span>
              </div>
            </li>

            <li>
              <div class="exchangeRate">
                <span class="">{{ $fanyi("配送单汇率") }}：</span>
                <span
                  >1元={{ $fun.ceil($parent.porderData.exchange_rate) }}円</span
                >
              </div>
              <div
                @click="
                  !['临时保存', '装箱中'].includes($parent.porder_status_name)
                    ? $parent.translateCostDetailsToCamelCase()
                    : ''
                "
              >
                <span class="numTitle">{{ $fanyi("国际运费2") }}：</span>
                <span class="priceFont">
                  <!--2023/5/16 这里原本都是使用pay_amount_jpy计算的,因为优惠券是日元,但是总计是99.99的情况下换算会多0.1元,所以使用sum_amount计算人民币 -->
                  {{
                    $fun.JPYNumSegmentation(
                      $fun.ceil(porder_amount.sum_amount)
                    )
                  }}元 ({{
                    $fun.JPYNumSegmentation(porder_amount.pay_amount_jpy)
                  }}円)
                </span>
              </div>
            </li>
          </ul>
        </div>
        <div
          class="paymentOperation"
          v-if="
            ['等待付款'].includes(porder_status_name) &&
            !$parent.porderData.p_porder_sn
          "
        >
          <button
            class="couponUse"
            @click="$parent.openUseCouponModal"
            v-if="$parent.couponList && $parent.couponList.length"
          >
            <input type="checkbox" :checked="$parent.hasUsedCoupon" />
            {{
              $parent.hasUsedCoupon
                ? $fanyi("优惠券使用中")
                : $fanyi("使用优惠券")
            }}
          </button>
          <button class="pay" @click="$parent.proceedToPayment">
            {{ $fanyi("立即付款") }}
          </button>
        </div>
      </div>
    </template>
    <!-- 是否已使用优惠券 -->
    <!-- <template
      v-if="!['临时保存', '装箱中', '等待付款'].includes(porder_status_name)"
    >
      {{ $fanyi("已使用免手续费券") }}
    </template> -->
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, computed } from "vue";
const { proxy } = getCurrentInstance();

//------------------------ data -------------------------------------
const props = defineProps({
  // 这个是否是订单明细弹窗里引用的, true=是
  isInOrderDetailPopup: {
    default: false,
  },
});
const porder_amount = computed(() => {
  return (
    proxy.$parent.porder_amount ||
    proxy.$parent.$parent.$parent.$parent.porder_amount
  );
});
const variousStatisticsData = computed(() => {
  return (
    proxy.$parent.variousStatisticsData ||
    proxy.$parent.$parent.$parent.$parent.variousStatisticsData
  );
});
const orderStatus = computed(() => {
  return (
    proxy.$parent.orderStatus ||
    proxy.$parent.$parent.$parent.$parent.orderStatus
  );
});
const porder_status_name = computed(() => {
  return (
    proxy.$parent.porder_status_name ||
    proxy.$parent.$parent.$parent.$parent.porder_status_name
  );
});
const porder_status_Num_NoChange = computed(() => {
  return (
    proxy.$parent.porder_status_Num_NoChange ||
    proxy.$parent.$parent.$parent.$parent.porder_status_Num_NoChange
  );
});
const porder_canEdit = computed(() => {
  return proxy.$parent.porder_canEdit != undefined
    ? proxy.$parent.porder_canEdit
    : proxy.$parent.$parent.$parent.$parent.porder_canEdit;
});
const isInOrderDetailPopup = ref(props.isInOrderDetailPopup);
const userOperationMode = computed(() => {
  return (
    proxy.$parent.userOperationMode ||
    proxy.$parent.$parent.$parent.$parent.userOperationMode
  );
});
const delCart = computed(() => {
  return proxy.$parent.delCart || proxy.$parent.$parent.$parent.$parent.delCart;
});
const allChoice = computed(() => {
  return (
    proxy.$parent.allChoice || proxy.$parent.$parent.$parent.$parent.allChoice
  );
});

const totalPrice = computed(() => {
  return (
    proxy.$parent.totalPrice || proxy.$parent.$parent.$parent.$parent.totalPrice
  );
});
const goodsTotal = computed(() => {
  return (
    proxy.$parent.goodsTotal || proxy.$parent.$parent.$parent.$parent.goodsTotal
  );
});
const createOrder = computed(() => {
  return (
    proxy.$parent.createOrder ||
    proxy.$parent.$parent.$parent.$parent.createOrder
  );
});
const selectedItemQuantity = computed(() => {
  return (
    proxy.$parent.selectedItemQuantity ||
    proxy.$parent.$parent.$parent.$parent.selectedItemQuantity
  );
});
const orderNumData = computed(() => {
  return (
    proxy.$parent.orderNumData ||
    proxy.$parent.$parent.$parent.$parent.orderNumData
  );
});

//------------------------ pageLoad -------------------------------------
//------------------------ methods -------------------------------------
defineExpose({});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.priceFont {
  color: #b4272b;
  font-weight: 600;
}
.cartBottom {
  margin-top: auto;
  position: sticky;
  bottom: 0px;
  .dianzi {
  }
  .openOrderDetailModal {
    width: 750px;
    height: 64px;
    background: #f9f9f9;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 65px;
    border-top: #dfdfdf 1px solid;
    border-bottom: #dfdfdf 1px solid;
    &:last-child {
      border-top: none;
    }
    span {
      color: #b4272b;
      font-size: 24px;
    }
    img {
      width: 12px;
      height: 7px;
    }
  }
  .con {
    background: #ffffff;
    border: #dfdfdf solid 1px;
    border-left: none;
    border-right: none;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    span {
      display: inline-block;
      font-size: 24px;
      line-height: 1;
    }
    input[type="checkbox"] {
      margin: 0;
      flex: 0 0 24px;
      height: 24px;
      margin-right: 6px;
    }
    .quanXuan {
      margin-right: 30px;
    }
    .numBox {
      * {
        font-size: 24px;
      }
      .fanshu {
        width: 120px;
        margin-bottom: 11px;
      }
      .toPri {
        .priceFont {
          font-weight: 600;
          color: #b4272b;
        }
      }
    }

    button {
      margin-left: auto;
      width: 150px;
      height: 70px;
      background: #b4272b;
      border-radius: 6px;
      font-size: 28px;
      line-height: 1;
      color: #ffffff;
    }
  }
  .requestOrderStatus {
    background-color: white;
    .openOrderDetailModal {
      width: 750px;
      height: 58px;
      background: #f9f9f9;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 0 65px;
      border-top: #dfdfdf 1px solid;
      border-bottom: #dfdfdf 1px solid;
      span {
        color: #b4272b;
        font-size: 24px;
      }
      img {
        width: 12px;
        height: 7px;
      }
    }
    .itemDataGroup {
      padding-left: 65px;
      padding-top: 16px;
      padding-bottom: 5px;
      display: flex;
      flex-wrap: wrap;
      .itemData {
        width: 340px;
        font-size: 24px;
        line-height: 41px;
        span {
          font-size: 24px;
        }
        span.tit {
          display: inline-block;
          //   width: 120px;
        }
      }
      .itemData.currentExchangeRate {
        span {
          font-size: 20px;
        }
      }
    }
    .buttonGroup {
      padding: 15px 65px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .useCoupon {
        min-height: 65px;
        flex: 1;
        input[type="checkbox"] {
          flex: 0 0 24px;
          height: 24px;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          margin-left: 0;
          margin-right: 11px;
        }
        p {
          display: flex;
          align-items: center;
          margin-bottom: 10px;
          &:last-child {
            margin-bottom: 0;
          }
          span {
            font-size: 24px;
          }
          .promptText {
            margin-left: 35px;
            color: #b4272b;
          }
        }
      }
      button {
        width: 280px;
        height: 70px;
        background: #b4272b;
        border-radius: 6px;
        font-size: 28px;
        font-weight: 400;
        color: #ffffff;
      }
      button.temporarySave {
        background: #27b470;
      }
    }
  }
  .waitingForPaymentActionColumn {
    background-color: white;
    padding: 20px 60px 20px;
    .deliveryOrderPaymentInformation {
      ul {
        display: flex;
        justify-content: space-between;
        li {
          display: flex;

          flex-direction: column;
          line-height: 30px;
          > div {
            margin-bottom: 10px;
          }
          span {
            font-size: 24px;
            line-height: 30px;
          }
        }
        .exchangeRate {
          span {
            font-size: 20px;

            font-weight: 400;
            color: #666666;
          }
        }
      }
    }
    .paymentOperation {
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 15px 0;
      button {
        width: 280px;
        height: 70px;
        border-radius: 6px;
        font-size: 24px;
      }
      button.couponUse {
        background: #f2f2f2;
        display: flex;
        justify-content: center;
        align-items: center;
        input[type="checkbox"] {
          margin: 0;
          margin-right: 10px;
          flex: 0 0 30px;
          width: 30px;
          height: 30px;
          border: 1px solid #dfdfdf;
          border-radius: 6px;
          pointer-events: none;
        }
      }
      button.pay {
        background: #b4272b;
        color: #ffffff;
        margin-left: auto;
      }
    }
  }
}
</style>
