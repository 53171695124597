<template>
  <div ref="menubox">
    <van-dropdown-menu class="xialakuang logisticsSelection">
      <van-dropdown-item v-model="menuValue" :options="options">
        <van-cell
          v-for="(menu_item, menu_index) in options"
          :key="menu_index"
          :title="menu_item.text"
          class="switchBox"
          :class="{
            active: menuValue == menu_item.id,
            disAbled: menu_item.is_vip == 1 && !isVip,
          }"
          @click="setValue(menu_item.id, menu_item.is_vip)"
        >
          <i
            v-if="menuValue == menu_item.id"
            style="color: #b4272b"
            class="van-badge__wrapper van-icon van-icon-success van-dropdown-item__icon"
          ></i>
        </van-cell>
      </van-dropdown-item>
    </van-dropdown-menu>
  </div>
  <oDialog
    ref="RwDia"
    :config="{
      title: $fanyi('提示'),
    }"
  >
    <div>
      <div class="contengBox">
        お客様は定額会員ではない為、定額会員限定ルート「RW船便」のご利用はできません。ご希望の場合は、定額会員を申し込んで頂くようにお願い致します。
      </div>
      <div class="btnList">
        <button class="whiteBtn" @click="close()">閉じる</button>
        <button
          @click="
            $fun.routerToPage('https://blog.rakumart.com/detail?id=73', true)
          "
        >
          RW船便説明ページへ
        </button>
        <button @click="$fun.routerToPage('/Memberfee', true)">
          今すぐ申し込む
        </button>
      </div>
    </div>
  </oDialog>
</template>
<script setup>
import oDialog from "@/components/Dialog/index.vue";
import { getCurrentInstance, ref, defineEmits, watch, inject } from "vue";
const { proxy } = getCurrentInstance();
const props = defineProps({
  options: {
    default: [],
  },
  modelValue: {
    default: null,
  },
});
const menuValue = ref();
const emits = defineEmits(["update:modelValue"]);

const isVip = inject("isVip");

const setValue = (v, is_vip) => {
  let set = () => {
    menuValue.value = v;
    emits("update:modelValue", v);
    proxy.$refs.menubox.click();
  };
  if (is_vip == 1) {
    if (isVip.value) {
      set();
    } else {
      proxy.$refs.RwDia.open();
    }
  } else {
    set();
  }
};

const close = () => {
  proxy.$refs.RwDia.show = false;
};

watch(
  () => props.modelValue,
  (newValue, oldValue) => {
    console.log("执行");
    menuValue.value = newValue;
  },
  { immediate: true }
);

defineExpose({ menuValue });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.xialakuang {
  .van-cell__title {
    span {
      font-size: 28px;
      white-space: nowrap;
    }
  }
  .van-popup {
    margin: 0 !important;
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
  .van-dropdown-item__option--active {
    .van-cell__title {
      span {
        font-size: 28px;
        color: #b4272b;
      }
    }

    .van-dropdown-item__icon {
      color: #b4272b;
    }
  }
}
.contengBox {
  padding: 5px 20px;
  font-size: 24px;
  line-height: 30px;
}

.btnList {
  padding: 40px 20px 0;
  display: flex;
  justify-content: space-around;
  button {
    width: 180px;
    min-height: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px 20px;
    background: #b4272b;
    border-radius: 6px;
    font-size: 20px;
    color: #ffffff;
  }
  .whiteBtn {
    background-color: white;
    color: #000000;
    border: #000000 solid 1px;
  }
}
</style>
<style lang="scss">
.logisticsSelection {
  width: 380px !important;
  margin-bottom: 10px;
  .van-dropdown-menu__bar {
    height: 64px;
    box-shadow: none;
    border: 1px solid #dfdfdf;
    border-radius: 6px;

    .van-dropdown-menu__title {
      width: 100%;
      padding: 0 30px;
      .van-ellipsis {
        font-size: 24px;
        // color: #b4272b;
      }

      &:after {
        right: 13px;
        color: #b4272b;
      }
    }

    .van-dropdown-menu__title--active {
      color: #000000;
    }
  }

  .active {
    color: #b4272b;
  }
  .van-cell {
    display: none;
  }
  .switchBox {
    display: flex;
    margin: 0 30px;
    width: 680px;
    &:active {
      background-color: #f2f3f5;
    }
  }
  .disAbled {
    background-color: #ffffff;
    color: #bbbbbb;
  }
}
</style>
