<!-- 配送单详情弹窗 -->
<template>
  <div>
    <van-action-sheet v-model:show="show" :title="$fanyi('装箱信息')">
      <div class="modelPage">
        <div
          class="xiangziOpt"
          v-for="(xiangItem, xiangIndex) in xianghaoList"
          :key="xiangIndex"
          @click="
            $fun.routerToPage(
              `/deliveryEncasementDetails?id=${$route.query.pOrder_sn}&boxNum=${xiangIndex}`
            )
          "
        >
          <h1>
            <span class="xianghao"
              >{{ $fanyi("箱号") }}：{{ xiangItem.number }}</span
            ><span
              >{{ $fanyi("物流方式") }}：{{ xiangItem.logistics_name }}</span
            >
          </h1>
          <p>
            <span class="title">{{ $fanyi("实际重量(kg)") }}：</span>
            <span>{{ xiangItem.weight }}</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("长(cm)") }}：</span>
            <span>{{ xiangItem.length }}</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("宽(cm)") }}：</span>
            <span>{{ xiangItem.width }}</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("高(cm)") }}：</span>
            <span>{{ xiangItem.height }}</span>
          </p>
          <p>
            <span class="title">{{ $fanyi("体积(cm³)") }}：</span>
            <span>{{ xiangItem.volume }}</span>
          </p>
          <p @click.stop="logisticsSelectPageJump(xiangItem)">
            <span class="title">{{ $fanyi("计费重量") }}（kg）：</span>
            <span>{{ xiangItem.volume_weight }}</span>
          </p>
          <p @click.stop="logisticsSelectPageJump(xiangItem)">
            <span class="title">{{ $fanyi("国际快递单号") }}：</span>
            <span class="aLink">{{ xiangItem.express_no }}</span>
          </p>
        </div>
      </div>
      <logisticsInformation ref="LogisticsInformation" />
    </van-action-sheet>
  </div>
</template>
<script setup>
import { getCurrentInstance, ref, defineProps } from "vue";
import logisticsInformation from "./logisticsInformation.vue";
const { proxy } = getCurrentInstance();
const show = ref(false);
const xianghaoList = ref([]);
//--------------------------------methods--------------------------

const open = (data) => {
  show.value = true;
  xianghaoList.value = data;
};
const logisticsSelectPageJump = (val) => {
  if (
    [
      "TW船便",
      "海源DQ船便",
      "KS-JP航空便",
      "RW船便",
      "Raku-KS",
      "EMS",
      "OCS",
      "Raku-DQ",
      "海源TK船便",
      "FBA新幹線",
    ].includes(val.logistics_name)
  ) {
    proxy.$refs.LogisticsInformation.open({ express_no: val.express_no });
  } else {
    let oInput = document.createElement("input"); //创建一个隐藏input（重要！）
    oInput.value = val.express_no; //赋值
    document.body.appendChild(oInput);
    oInput.select(); // 选择对象
    document.execCommand("Copy"); // 执行浏览器复制命令
    oInput.className = "oInput";
    oInput.style.display = "none";
    proxy.$toast("追跡番号をコピーしました");
    if (val.logistics_select.href !== "") {
      if (val.logistics_select.method === "get") {
        let str = "?";
        let url = proxy.$fun.deepClone(val.logistics_select.href);
        Object.keys(val.logistics_select.data).forEach((key, index) => {
          if (index === 0) {
            str += key + "=" + val.logistics_select.data[key];
          } else {
            str += "&" + key + "=" + val.logistics_select.data[key];
          }
        });
        url += str;
        window.open(url);
      } else {
        var postForm = document.createElement("form"); //创建form表单
        postForm.method = "post"; //设置请求方式
        postForm.action = val.logistics_select.href; //设置url
        postForm.target = "_blank"; //用来将数据提交到ifrom上（将页面展示在ifrom），删掉这一行会导致页面跳转
        Object.keys(val.logistics_select.data).forEach((key, index) => {
          var custGroup = document.createElement("input"); //创建input框，用以提交参数
          custGroup.type = "hidden"; //设置input样式
          custGroup.name = key; //这个是传值的名称，要记住
          custGroup.value = val.logistics_select.data[key]; //传值
          postForm.appendChild(custGroup); //添加input框到form表单中
        });
        document.body.appendChild(postForm); //添加form表单到body
        postForm.submit(); //提交form表单
        document.body.removeChild(postForm); //将body中的form表单删除
      }
    }
  }
};
//-----------------------load-------------------

defineExpose({ open });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.modelPage {
  padding: 0 30px 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  .xiangziOpt {
    width: 690px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 20px;
    padding: 30px;
    margin-bottom: 15px;
    h1 {
      display: flex;
      height: 42px;
      align-items: center;
      margin-bottom: 10px;
      span {
        font-size: 28px;
      }
      .xianghao {
        margin-right: 70px;
      }
    }
    p {
      display: flex;
      align-items: center;
      height: 36px;
      margin-bottom: 10px;
      &:last-child {
        margin-bottom: 0;
      }
      span {
        font-size: 24px;
      }
      .title {
        display: inline-block;
        width: 230px;
        text-align: right;
        color: #999999;
      }
    }
  }
}
.readOnly {
  pointer-events: none;
  textarea {
    background-color: #f2f2f2 !important;
  }
  input,
  :deep(input) {
    background-color: #f2f2f2 !important;
  }
  :deep(.van-dropdown-menu__bar) {
    background-color: #f2f2f2 !important;
  }
}
</style>
<style lang="scss">
.xialakuang {
  .van-cell__title {
    span {
      font-size: 28px;
    }
  }
  .van-popup {
    margin: 0 !important;
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
  .van-dropdown-item__option--active {
    .van-cell__title {
      span {
        font-size: 28px;
        color: #b4272b;
      }
    }

    .van-dropdown-item__icon {
      color: #b4272b;
    }
  }
}
</style>
