<!-- 配送单详情弹窗 -->
<template>
  <div>
    <van-action-sheet
      v-model:show="show"
      :lazy-render="false"
      :title="$fanyi('配送信息')"
      class="dedepUp"
    >
      <div
        class="modelPage"
        :class="{
          readOnly: !['临时保存'].includes($parent.porder_status_name),
        }"
      >
        <div class="pageInfo">
          <h1>{{ $fanyi("国際運送会社選択") }}：</h1>
          <div
            class="wuliufangshi"
            v-if="
              !['临时保存'].includes($parent.porder_status_name) &&
              porderData &&
              porderData.porder_logistics_name
            "
          >
            {{ porderData.porder_logistics_name }}
          </div>
          <div v-else class="selectBox">
            <img
              src="https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202405/6646f77152296.png"
              alt=""
              v-if="porderData.logistics_id == 20"
            />
            <mydropdownmenu
              v-model="porderData.logistics_id"
              :options="$parent.interList"
            />
          </div>

          <div class="logisticsReminder">
            <p
              style="margin-bottom: 0.1rem"
              v-if="porderData.logistics_id == 20"
            >
              {{ $fanyi("定额会员限定路线，设定12天的时限。") }}
            </p>
            <p>{{ $fanyi("注意:国际货物通关时产生的关税将由客人承担。") }}</p>
            <p>
              {{
                $fanyi(
                  "注意:EMS除外的配送方式是由运输公司垫付税金后再向客户收取。(有可能产生垫付手续费)"
                )
              }}
            </p>
          </div>
          <h1>{{ $fanyi("特殊要求备注") }}:</h1>
          <textarea
            class="specialRequirementsRemark inputBox"
            :rows="
              porderData.client_remark &&
              typeof porderData.client_remark == 'string'
                ? porderData.client_remark.length / 50 +
                  porderData.client_remark.split('\n').length
                : 1
            "
            v-model="porderData.client_remark"
            :placeholder="
              $parent.porder_status_name != '临时保存'
                ? ''
                : $fanyi('请输入备注信息')
            "
          />
        </div>
        <!-- 收件地址 -->
        <div class="addressManagement recipientAddress">
          <div class="predefinedAddressSelection">
            <span class="addressTypeName">{{ $fanyi("收件地址") }}：</span>
            <van-dropdown-menu
              class="xialakuang addressDropdownSelection"
              v-if="$parent.porder_status_name == '临时保存'"
            >
              <van-dropdown-item
                v-model="value1"
                :options="userAddressList"
                @click="setAddress(value1, 'receiver_address', 'formRulesooo')"
              />
            </van-dropdown-menu>
          </div>
          <van-form ref="formRulesooo" class="addressEditBox">
            <!-- 氏名 -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.name == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('name')"
                v-model.trim="porderData.receiver_address.name"
                error
                required
                name="name"
                label-align="top"
                :label="$fanyi('氏名')"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="formRules.name == 2 && displayMode == 'edit'"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="formRules.name == 0 && displayMode == 'edit'"
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- ローマ字（氏名）： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.name_rome == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('name_rome')"
                v-model.trim="porderData.receiver_address.name_rome"
                error
                name="name_rome"
                label-align="top"
                :label="$fanyi('ローマ字（氏名）：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.name_rome"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 公司名： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.company == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('company')"
                v-model.trim="porderData.receiver_address.company"
                error
                name="company"
                label-align="top"
                :label="$fanyi('公司名') + '：'"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.company"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 住所 -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.address == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('address')"
                v-model.trim="porderData.receiver_address.address"
                error
                required
                name="address"
                label-align="top"
                :label="$fanyi('住所') + '：'"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="formRules.address == 2 && displayMode == 'edit'"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="formRules.address == 0 && displayMode == 'edit'"
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- ローマ字（住所）： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    formRules.address_rome == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('address_rome')"
                v-model.trim="porderData.receiver_address.address_rome"
                error
                name="address_rome"
                label-align="top"
                :label="$fanyi('ローマ字（住所）：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.address_rome"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 邮编： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.zip == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('zip')"
                v-model.trim="porderData.receiver_address.zip"
                error
                required
                name="zip"
                label-align="top"
                :label="$fanyi('邮编') + '：'"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                  {
                    validator: postcodeValidation,
                    message: '邮编格式不正确',
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="formRules.zip == 2 && displayMode == 'edit'"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="formRules.zip == 0 && displayMode == 'edit'"
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 手机： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.mobile == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('mobile')"
                v-model.trim="porderData.receiver_address.mobile"
                error
                required
                name="mobile"
                label-align="top"
                :label="$fanyi('手机：')"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                  {
                    validator: phoneValidation,
                    message: '手机号输入错误',
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="formRules.mobile == 2 && displayMode == 'edit'"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="formRules.mobile == 0 && displayMode == 'edit'"
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 座机： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.tel == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('tel')"
                v-model.trim="porderData.receiver_address.tel"
                error
                name="tel"
                label-align="top"
                :label="$fanyi('座机：')"
                :rules="[]"
              >
                <template #input>
                  <input
                    type="text"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    v-model.trim="porderData.receiver_address.tel"
                    name=""
                    id=""
                  />
                </template>
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.tel"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 法人番号： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    formRules.corporate_name == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('corporate_name')"
                v-model.trim="porderData.receiver_address.corporate_name"
                error
                name="corporate_name"
                label-align="top"
                :label="$fanyi('法人番号：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.corporate_name"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- リアルタイム口座： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: formRules.account == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('account')"
                v-model.trim="porderData.receiver_address.account"
                error
                name="account"
                label-align="top"
                :label="$fanyi('リアルタイム口座：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.account"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 標準コード： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    formRules.standard_code == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateField('standard_code')"
                v-model.trim="porderData.receiver_address.standard_code"
                error
                name="standard_code"
                label-align="top"
                :label="$fanyi('標準コード：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.receiver_address.standard_code"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
          </van-form>
        </div>
        <!--进口商  -->
        <div class="addressManagement importerAddress">
          <div class="predefinedAddressSelection">
            <span class="addressTypeName">{{ $fanyi("进口商") }}：</span>
            <van-dropdown-menu
              class="xialakuang addressDropdownSelection"
              v-if="$parent.porder_status_name == '临时保存'"
            >
              <van-dropdown-item
                v-model="value2"
                :options="userAddressList_jinkoushang"
                @click="setAddress(value2, 'importer_address', 'formRulesoooi')"
              />
            </van-dropdown-menu>
          </div>
          <van-form ref="formRulesoooi" class="addressEditBox">
            <!-- 氏名 -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.name == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('name')"
                v-model.trim="porderData.importer_address.name"
                error
                required
                name="name"
                label-align="top"
                :label="$fanyi('氏名')"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="importerformRules.name == 2 && displayMode == 'edit'"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="importerformRules.name == 0 && displayMode == 'edit'"
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- ローマ字（氏名）： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.name_rome == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('name_rome')"
                v-model.trim="porderData.importer_address.name_rome"
                error
                name="name_rome"
                label-align="top"
                :label="$fanyi('ローマ字（氏名）：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="
                      importerformRules.name_rome == 2 && displayMode == 'edit'
                    "
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="
                      importerformRules.name_rome == 0 && displayMode == 'edit'
                    "
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 公司名： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.company == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('company')"
                v-model.trim="porderData.importer_address.company"
                error
                name="company"
                label-align="top"
                :label="$fanyi('公司名') + '：'"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.importer_address.company"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 住所 -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.address == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('address')"
                v-model.trim="porderData.importer_address.address"
                error
                required
                name="address"
                label-align="top"
                :label="$fanyi('住所') + '：'"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="
                      importerformRules.address == 2 && displayMode == 'edit'
                    "
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="
                      importerformRules.address == 0 && displayMode == 'edit'
                    "
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- ローマ字（住所）： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.address_rome == 0 &&
                    displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('address_rome')"
                v-model.trim="porderData.importer_address.address_rome"
                error
                name="address_rome"
                label-align="top"
                :label="$fanyi('ローマ字（住所）：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.importer_address.address_rome"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 邮编： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: importerformRules.zip == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('zip')"
                v-model.trim="porderData.importer_address.zip"
                error
                required
                name="zip"
                label-align="top"
                :label="$fanyi('邮编') + '：'"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                  {
                    validator: postcodeValidation,
                    message: '邮编格式不正确',
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="importerformRules.zip == 2 && displayMode == 'edit'"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="importerformRules.zip == 0 && displayMode == 'edit'"
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 手机： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.mobile == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('mobile')"
                v-model.trim="porderData.importer_address.mobile"
                error
                required
                name="mobile"
                label-align="top"
                :label="$fanyi('手机：')"
                :rules="[
                  {
                    required: true,
                    message: $fanyi('这个选项不能为空'),
                    trigger: 'onBlur',
                  },
                  {
                    validator: phoneValidation,
                    message: '手机号输入错误',
                    trigger: 'onBlur',
                  },
                ]"
              >
                <template #right-icon>
                  <img
                    v-if="
                      importerformRules.mobile == 2 && displayMode == 'edit'
                    "
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                  <img
                    v-if="
                      importerformRules.mobile == 0 && displayMode == 'edit'
                    "
                    :src="require('@/assets/icon/cuowu.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 座机： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput: importerformRules.tel == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('tel')"
                v-model.trim="porderData.importer_address.tel"
                error
                name="tel"
                label-align="top"
                :label="$fanyi('座机：')"
                :rules="[]"
              >
                <template #input>
                  <input
                    type="text"
                    oninput="value=value.replace(/[^\d]/g,'')"
                    v-model.trim="porderData.importer_address.tel"
                    name=""
                    id=""
                  />
                </template>
                <template #right-icon>
                  <img
                    v-if="porderData.importer_address.tel"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 法人番号： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.corporate_name == 0 &&
                    displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('corporate_name')"
                v-model.trim="porderData.importer_address.corporate_name"
                error
                name="corporate_name"
                label-align="top"
                :label="$fanyi('法人番号：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.importer_address.corporate_name"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- リアルタイム口座： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.account == 0 && displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('account')"
                v-model.trim="porderData.importer_address.account"
                error
                name="account"
                label-align="top"
                :label="$fanyi('リアルタイム口座：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.importer_address.account"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
            <!-- 標準コード： -->
            <van-cell-group inset>
              <van-field
                :class="{
                  errinput:
                    importerformRules.standard_code == 0 &&
                    displayMode == 'edit',
                  sureinput: displayMode == 'look',
                }"
                @blur="validateFieldi('standard_code')"
                v-model.trim="porderData.importer_address.standard_code"
                error
                name="standard_code"
                label-align="top"
                :label="$fanyi('標準コード：')"
                :rules="[]"
              >
                <template #right-icon>
                  <img
                    v-if="porderData.importer_address.standard_code"
                    :src="require('@/assets/icon/duiao.png')"
                    alt=""
                  />
                </template>
              </van-field>
            </van-cell-group>
          </van-form>
        </div>
      </div>
    </van-action-sheet>
  </div>
</template>
<script setup>
import mydropdownmenu from "./my-dropdown-menu/index.vue";
import { getCurrentInstance, ref, defineProps, inject } from "vue";
const { proxy } = getCurrentInstance();
const show = ref(false);
const porderData = ref({
  receiver_address: {},
  importer_address: {},
}); //配送单数据
const value1 = ref(""); //收件地址下拉框绑定值
const value2 = ref(""); //进口商下拉框绑定值
const displayMode = ref("edit"); //edit=编辑 look=查看
const userAddressList = ref([]); //用户地址列表
const userAddressList_jinkoushang = ref([]); //用户地址_进口商列表

//--------------------------------methods--------------------------
// 选择地址数据
const setAddress = (val, formName, formRef) => {
  setTimeout(() => {
    porderData.value[formName] = JSON.parse(
      JSON.stringify(
        userAddressList.value.find((item) => {
          return item.title == val;
        })
      )
    );
    if (formRef == "formRulesooo") {
      for (let i in porderData.value[formName]) {
        proxy.$refs.formRulesooo
          .validate(i)
          .then((res) => {
            formRules.value[i] = 2;
          })
          .catch(() => {
            formRules.value[i] = 0;
          });
      }
    } else {
      for (let i in porderData.value[formName]) {
        proxy.$refs.formRulesoooi
          .validate(i)
          .then((res) => {
            importerformRules.value[i] = 2;
          })
          .catch(() => {
            importerformRules.value[i] = 0;
          });
      }
    }
  }, 10);
};
// 手机号校验
const phoneValidation = (val) => {
  // if (!/^(\w){11}$/.test(val)) {
  //   return false;
  // }
};
// 邮编校验
const postcodeValidation = (val) => {
  if (val.length > 8 || val.length < 7) {
    return false;
  }
};
const open = (data) => {
  show.value = true;
  porderData.value = data;
  // 如果订单状态是临时保存并且没有选择物流地址，就默认选择第一个物流地址
  // console.log(
  //   proxy.$parent.porder_status_name,
  //   !data.logistics_id && proxy.$parent.porder_status_name == "临时保存",
  //   proxy.$parent.porder_status_name
  // );
  // 打开弹窗时判断是否需要取第一个物流
  setTimeout(() => {
    if (
      !data.logistics_id &&
      proxy.$parent.porder_status_name == "临时保存" &&
      !proxy.$route.query.id
    ) {
      data.logistics_id = proxy.$parent.interList.value[0].id;
    }
  }, 200);
  // 假如是配送单详情就验证已填写的地址项;
  if (proxy.$route.query.pOrder_sn) {
    setTimeout(() => {
      for (let i in porderData.value["receiver_address"]) {
        proxy.$refs.formRulesooo
          .validate(i)
          .then((res) => {
            formRules.value[i] = 2;
          })
          .catch(() => {
            formRules.value[i] = 0;
          });
      }
      for (let i in porderData.value["importer_address"]) {
        proxy.$refs.formRulesoooi
          .validate(i)
          .then((res) => {
            importerformRules.value[i] = 2;
          })
          .catch(() => {
            importerformRules.value[i] = 0;
          });
      }
    }, 100);
  }
};

const formRules = ref({
  // 校验类型数据 0 错误 1或者找不到 正常 2 正确
});
const importerformRules = ref({
  // 校验类型数据 0 错误 1或者找不到 正常 2 正确
});
// 失去焦点校验表单 然后改变状态
const validateField = (type) => {
  proxy.$refs.formRulesooo
    .validate(type)
    .then((res) => {
      formRules.value[type] = 2;
    })
    .catch(() => {
      formRules.value[type] = 0;
    });
};
// 失去焦点校验表单 然后改变状态
const validateFieldi = (type) => {
  proxy.$refs.formRulesoooi
    .validate(type)
    .then((res) => {
      importerformRules.value[type] = 2;
    })
    .catch(() => {
      importerformRules.value[type] = 0;
    });
};
// 获取用户地址列表
const getUserAddressList = () => {
  proxy.$api.userAddressList().then((res) => {
    ////console.log('事件名',res)
    if (res.code != 0) return;
    //接下来的操作
    res.data.data.forEach((element, index) => {
      element.text = element.title || `${proxy.$fanyi("地址")}${index + 1}`;
      element.value = element.title;
    });
    // // 假如没有选择运输方式就默认选择第一个
    // if (!proxy.$parent.porderData.logistics_id) {
    //   proxy.$parent.porderData.logistics_id = res.data.data[0].id;
    // }
    userAddressList_jinkoushang.value = JSON.parse(
      JSON.stringify(res.data.data)
    );
    res.data.data.push({
      id: 999,
      value: "Amazon仮配送先",
      name: "Amazon.co.jp FBA入庫係",
      company: "",
      address: "日本",
      zip: "000-0000",
      mobile: "000-0000-0000",
      tel: "",
      name_rome: "",
      address_rome: "",
      corporate_name: "",
      account: "",
      standard_code: "",
      title: "Amazon仮配送先",
      text: "Amazon仮配送先",
    });

    userAddressList.value = res.data.data;
    // console.log(res.data.data);
  });
};
// 检查地址数据
const validateAddressData = async () => {
  console.log(proxy.$refs.formRulesooo.validate());
  let flog = true;
  if (!proxy.$refs.formRulesooo) {
    return false;
  }
  await proxy.$refs.formRulesooo
    .validate()
    .then(() => {})
    .catch(() => {
      flog = false;
    });
  await proxy.$refs.formRulesoooi
    .validate()
    .then(() => {})
    .catch(() => {
      flog = false;
    });
  // console.log("kl");
  return flog;
};
//-----------------------load-------------------
// internationalLogisticsTransportation();
getUserAddressList();
defineExpose({ open, validateAddressData, value1, value2, porderData });
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
:deep(.van-action-sheet__header) {
  text-align: center !important;
}
.dedepUp {
  .van-action-sheet__header {
    z-index: 3 !important;
  }
}
.modelPage {
  padding: 0 30px 40px;
  display: flex;
  flex-direction: column;
  .pageInfo {
    padding: 20px 30px 0;
  }
  .selectBox {
    display: flex;
    img {
      width: 60px;
      height: 60px;
      margin-right: 20px;
    }
  }
  .wuliufangshi {
    width: 380px;
    height: 64px;
    background: #f2f2f2;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    margin-bottom: 10px;
    font-size: 24px;
  }
  h1 {
    font-size: 28px;
    font-weight: 400;
    color: #000000;
    line-height: 42px;
    margin-bottom: 20px;
  }

  .logisticsReminder {
    line-height: 30px;
    font-size: 20px;
    font-weight: 400;
    color: #f32a2a;
    margin-bottom: 40px;
  }
  .inputBox {
    width: 630px;
    // height: 64px;
    background: #ffffff;
    border: 1px solid #dfdfdf;
    border-radius: 6px;
    font-size: 24px;
    line-height: 30px;
    padding: 14px 30px;
  }
  .specialRequirementsRemark {
    margin-bottom: 40px;
  }
  .addressManagement {
    width: 690px;
    background: #fafafa;
    border-radius: 6px;
    padding: 30px 30px 10px;
    margin-bottom: 40px;
    &:last-child {
      margin-bottom: 0;
    }
    .predefinedAddressSelection {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 20px;
      .addressTypeName {
        font-size: 28px;
      }
      .addressDropdownSelection,
      :deep(.addressDropdownSelection) {
        width: 280px !important;
        margin-bottom: 10px;
        .van-dropdown-menu__bar {
          height: 64px;
          box-shadow: none;
          border: 1px solid #dfdfdf;
          border-radius: 6px;

          .van-dropdown-menu__title {
            width: 100%;
            padding: 0 30px;
            .van-ellipsis {
              font-size: 24px;
              // color: #b4272b;
            }

            &:after {
              right: 13px;
              color: #b4272b;
            }
          }

          .van-dropdown-menu__title--active {
            color: #000000;
          }
        }
      }
    }
    .addressEditBox,
    :deep(.addressEditBox) {
      .van-cell-group {
        margin: 0;
        background-color: transparent;
        border-radius: 0px;
        * {
          border-radius: 6px;
          overflow: visible !important;
        }
        .van-cell {
          padding: 0;
          background-color: transparent;
          display: flex;
          flex-direction: column;
          .van-cell__title {
            width: 100%;
            height: 36px;
            display: flex;
            align-items: center;
            margin-bottom: 10px;
            label {
              font-size: 24px;
            }
          }
          .van-field__body {
            overflow: visible;
            input {
              padding: 10px 30px;
              min-height: 64px;
              background: #ffffff;
              border: 1px solid #dfdfdf;
              border-radius: 6px;
              font-size: 24px;
              color: #000000;
            }
          }
          .van-field__error-message {
            font-size: 20px;
            line-height: 26px;
            min-height: 26px;
            display: flex;
            align-items: center;
          }
          .van-cell__value {
            min-height: 90px;
          }
          .van-field__right-icon {
            position: absolute;
            right: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
            img {
              width: 20px;
            }
          }
        }
      }
    }
  }
}
.readOnly {
  pointer-events: none;
  textarea {
    background-color: #f2f2f2 !important;
  }
  input,
  :deep(input) {
    background-color: #f2f2f2 !important;
  }
  :deep(.van-dropdown-menu__bar) {
    background-color: #f2f2f2 !important;
  }
}
</style>
<style lang="scss">
.xialakuang {
  .van-cell__title {
    span {
      font-size: 28px;
      white-space: nowrap;
    }
  }
  .van-popup {
    margin: 0 !important;
    width: 100%;
    padding: 0;
    border-radius: 0;
  }
  .van-dropdown-item__option--active {
    .van-cell__title {
      span {
        font-size: 28px;
        color: #b4272b;
      }
    }

    .van-dropdown-item__icon {
      color: #b4272b;
    }
  }
}
</style>
