<template>
  <div class="pageF6Color" :class="{ readOnly: loading }">
    <div>
      <headgobackVue :iCustomizdde="true">
        <template #default>
          <span> 配送依頼書 </span>
          <div
            class="headerButtonBox"
            v-if="['临时保存'].includes(porder_status_name) || porder_canEdit"
          >
            <div v-if="userOperationMode == 'propose'">
              <van-popover
                v-model:show="showPopover"
                placement="bottom-end"
                class="headBtnGroup"
                :actions="
                  porder_status_Num !== 10 && porder_canEdit
                    ? editCation
                    : actions
                "
                @select="onSelect"
              >
                <template #reference>
                  <button>
                    {{ $fanyi("管理") }}
                  </button>
                </template>
              </van-popover>
            </div>
            <button
              class="redFont"
              v-else
              @click="userOperationMode = 'propose'"
            >
              {{ $fanyi("完成") }}
            </button>
          </div>
        </template>
      </headgobackVue>
      <!-- 配送单修改提示 -->
      <div
        class="deliveryOrderModificationPrompt"
        v-if="porder_status_Num >= 30"
      >
        <img :src="require('@/assets/icon/danhong.png')" alt="" />
        {{ $fanyi("配送单需要调整或者有其他疑问，请直接与负责人联系！") }}
      </div>
      <!-- v-if="['临时保存'].includes(porder_status_name) && $route.query.id" -->
      <getDeliveryStatusList v-if="['临时保存'].includes(porder_status_name)" />
      <quotationInProgressProductList v-else />
      <addProductsToDeliveryOrder ref="addProductsToDeliveryOrderRef" />
      <deliveryAddress ref="deliveryAddressRef" />
      <costDetails ref="costDetailsRef" />
    </div>
    <zhuangxiangxinxi ref="zhuangxiangxinxiRef" />
    <userDeliveryOrderOperation />
    <myDialog ref="myDialogddd" />
    <coupon ref="couponVueRef" />
    <mloading v-show="loading" />
  </div>
</template>
<script setup>
import headgobackVue from "@/components/headGoBack";
import getDeliveryStatusList from "./components/getDeliveryStatusList.vue";
import userDeliveryOrderOperation from "./components/userDeliveryOrderOperation.vue";
import deliveryAddress from "./components/deliveryAddress.vue";
import myDialog from "@/components/Dialog/index.vue";
import costDetails from "./components/costDetails.vue";
import addProductsToDeliveryOrder from "./components/addProductsToDeliveryOrder.vue";
import quotationInProgressProductList from "./components/quotationInProgressProductList.vue";
import mloading from "../../components/loading";
import zhuangxiangxinxi from "./components/zhuangxiangxinxi.vue";
import coupon from "./components/coupon.vue";
import { getCurrentInstance, ref, computed, provide } from "vue";
const headStr = ref("配送依頼書"); //头部标题文字
const deliveryOrderExchangeRate = ref(0); //配送单汇率
const { proxy } = getCurrentInstance();
const variousStatisticsData = ref({
  porderHasIds: [], //配送单里已有商品
  // productQuantity: 0, //商品番数 2023/4/20 小何接口已经算好了,所以注释
  // totalProductQuantity: 0, //商品总数
}); // 各种统计数据
//--------------------------data--------------------------------------
// const headTextList = ref([

// ]); //配送单各状态对应头部文字
const showPopover = ref(false); //是否打开头部菜单
const userOperationMode = ref("propose"); //操作栏模式 propose为提出配送模式,delete为删除模式
const tableData = ref([]); //提出商品的表格数据
const porder_sn = ref(""); //配送单号
const porderData = ref({}); //配送单所有数据
const porder_status_name = ref("装箱中"); // 配送单状态;
const porder_status_Num = ref(10); // 配送单状态;
const porder_status_Num_NoChange = ref(10); // 配送单状态;
const checked = ref(false); //是否全选
const loading = ref(true); //是否加载中
const idArr = ref([]);
const hasUsedCoupon = ref(false); //是否已经使用优惠券
const usedCouponInfo = ref({}); //已使用优惠券的信息
const couponList = ref([]); //优惠券列表
const porder_canEdit = ref(false); //是否可以修改配送单
const porder_amount = ref({
  send_count: 0, //提出番数
  send_num: 0, //提出商品数量
  international_logistics_fee: 0, //国际运费合计（人民币）
  international_logistics_fee_jpy: 0, //国际运费合计（日元）
  other_fee: 0, //箱子的其他费用合计（人民币）
  other_fee_jpy: 0, //箱子的其他费用合计（日元）
  sum_amount: 0, //总费用合计（人民币）
  sum_amount_jpy: 0, //总费用合计（日元）
  discounts_amount_jpy: 0, //优惠金额合计（日元）
  discounts_detail: [], //优惠明细
  pay_amount_jpy: 0, //实际支付金额（日元）
}); //配送单信息
const interList = ref([]); //物流方式列表
const isTishi = ref(false); //提示弹窗使用

const isVip = computed(() => {
  return (
    proxy.$store.state.userInfo.level &&
    ["SVIP", "定額会員"].includes(
      proxy.$store.state.userInfo.level.currentLevel.level_name
    )
  );
});

provide("isVip", isVip);
//--------------------------methods--------------------------------------
// 全选
const allChoice = () => {
  tableData.value.forEach((tableDataItem) => {
    tableDataItem.checked = checked.value;
  });
  commodityTotal();
};
// 单选
const oddChoice = (i) => {
  var flag = tableData.value.every((item, index) => {
    return item.checked == true;
  });
  if (flag == true) {
    checked.value = true;
  } else {
    checked.value = false;
  }
  commodityTotal();
};
// 打开使用优惠券弹窗
const openUseCouponModal = () => {
  proxy.$refs.couponVueRef.open();
};
// 打开配送单地址编辑弹窗
const opendeliveryAddress = () => {
  proxy.$refs.deliveryAddressRef.open(porderData.value);
};
// 获取物流列表
const internationalLogisticsTransportation = () => {
  proxy.$api.internationalLogisticsTransportation().then((res) => {
    if (res.code != 0) return;
    res.data.forEach((element) => {
      element.text = element.name;
      element.value = element.id;
    });

    // console.log(res.data);
    interList.value = res.data;

    let vip_logistics = interList.value.filter((ii) => ii.is_vip == 1);
    vip_logistics.forEach((element) => {
      element.text = element.text + "-定額会員限定ルート";
    });
  });
};
// 打开费用明细
const translateCostDetailsToCamelCase = () => {
  proxy.$refs.costDetailsRef.open({
    porder_amount: porderData.value.porder_amount,
    logistics_group: porderData.value.logistics_group,
    porder_box: porderData.value.porder_box,
  });
};
// 前往付款
const proceedToPayment = () => {
  let meth = () => {
    proxy.$fun.routerToPage(
      `/payment?porder_sn=${porder_sn.value}&discounts_id=${
        usedCouponInfo.value.id || ""
      }`
    );
  };
  if (isTishi.value) {
    return meth();
  }
  isTishi.value = true;
  if (porderData.value.porder_amount.other_fee) {
    proxy.$refs.myDialogddd.open({
      title: proxy.$fanyi("提示"),
      text: proxy.$fanyi(
        "追加費用が発生しております。詳しくは「その他」の詳細ボタンをクリックしてからご確認下さい。"
      ),
      successBtn: proxy.$fanyi("确认"),
    });
  } else {
    meth();
  }
};
const openBoxDetail = () => {
  proxy.$refs.zhuangxiangxinxiRef.open(porderData.value.porder_box);
};
// 打开追加商品
const openAddAdditionalProductModal = () => {
  // 统计已在配送单中的商品
  proxy.$refs.addProductsToDeliveryOrderRef.open({
    overlook_order_detail_id:
      variousStatisticsData.value.porderHasIds.join(","),
  });
};
// 接收追加数据
const receiveAdditionalData = (data) => {
  console.log(tableData.value, data);
  handleData(data);
  data.forEach((item) => {
    tableData.value.push(item);
  });
  commodityTotal();
};
// 点击管理弹出框事件
const onSelect = (action) => {
  if (action.value) {
    userOperationMode.value = action.value;
  }
  if (action.methods) {
    action.methods();
  }
};
// 头部管理菜单按钮列表
const actions = [
  {
    text: proxy.$fanyi("追加商品"),
    methods: openAddAdditionalProductModal,
    icon: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202305/64549e56e91e5.png",
  },
  {
    text: proxy.$fanyi("删除商品"),
    value: "delete",
    icon: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202305/64549ea29a596.png",
  },
];
// 将配送单改为待修改
const editPorder = () => {
  porder_status_name.value = "临时保存";
  porder_status_Num.value = 10;
};
// 修改配送单头部管理菜单按钮列表
const editCation = [
  {
    text: proxy.$fanyi("修改配送单"),
    methods: editPorder,
    icon: "https://rakumart-ps20.oss-ap-northeast-1.aliyuncs.com/202305/64549e56e91e5.png",
  },
];
// 提出配送单
const generateDeliveryOrder = async (type) => {
  switch (type) {
    case "submit":
      porderData.value.create_type = "send";
      break;
    default:
      porderData.value.create_type = "save";
      break;
  }
  // 提出配送单时验证信息
  if (type == "submit") {
    //type == "submit"
    // console.log(porderData.value.logistics_id, 33);
    // 配送单提出钱验证信息
    if (!porderData.value.logistics_id) {
      return proxy.$message.warning(proxy.$fanyi("请选择物流方式"));
    }
    if (
      JSON.stringify(proxy.$refs.deliveryAddressRef.porderData) ==
      JSON.stringify({
        receiver_address: {},
        importer_address: {},
      })
    ) {
      proxy.$refs.deliveryAddressRef.porderData = porderData.value;
    }
    let aa = await proxy.$refs.deliveryAddressRef.validateAddressData();

    if (!aa) {
      return proxy.$message.warning(proxy.$fanyi("请填写正确物流信息"));
    }
  }
  if (!porderData.value.porder_detail) {
    porderData.value.porder_detail = porderData.value.porder_data;
  }
  for (let i in porderData.value.porder_data) {
    if (!porderData.value.porder_data[i].send_num) {
      return proxy.$message.warning(proxy.$fanyi("提货数量不能为零"));
    }
  }
  porderData.value.porder_detail = tableData.value;
  proxy.$api.storageSendDeliver(porderData.value).then((res) => {
    if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
    //接下来的操作
    if (type == "tempSave") {
      // 这里需要延迟跳转,防止配送单列表返回错误的数据
      setTimeout(() => {
        proxy.$fun.routerToPage("/user/deliveryList?type=temporary");
      }, 300);
    }
    // 假如点击的是提出按钮
    if (type == "submit") {
      // 这里需要延迟跳转,防止配送单列表返回错误的数据
      setTimeout(() => {
        proxy.$fun.routerToPage("/user/deliveryList?type=vanning");
      }, 300);
    }
  });
};

// 处理数据(加载时使用)
const handleData = (data) => {
  // 提出配送单状态处理数据方法
  let temporarySave = (dataelement) => {
    dataelement.send_num = dataelement.send_await_num; //提货数量默认为可提出数量
    dataelement.order_detail_id = dataelement.id;
    if (dataelement.order_detail_tag) {
      // order_detail_id必须删掉,不然接口会报错
      dataelement.order_detail_tag.forEach((element) => {
        delete element.order_detail_id;
      });
      // porderPreview(仓库提出配送单数据预览)这个接口没有返回porder_detail_tag,所以转化
      dataelement.porder_detail_tag = JSON.parse(
        JSON.stringify(dataelement.order_detail_tag)
      );
      delete dataelement.order_detail_tag;
    }
  };
  // 追加商品处理数据方法
  let zhuijia = (dataelement) => {
    // dataelement.client_remark = dataelement.box_client_remark;
    dataelement.order_detail_id = dataelement.id;
    dataelement.send_num = dataelement.send_await_num;
    dataelement.checked = false;
  };
  // 其他状态处理数据方法;
  let formallyPropose = (dataelement) => {
    // dataelement.client_remark = dataelement.box_client_remark;
  };
  // arr-接口传入的数据或者配送单列表数据
  let arr = data || tableData.value;
  arr.forEach((dataelement) => {
    if (data) {
      zhuijia(dataelement);
    } else if (proxy.$route.query.id) {
      temporarySave(dataelement);
    } else {
      formallyPropose(dataelement);
    }
  });
};
// 计算数据(更改数据时使用)
const commodityTotal = () => {
  variousStatisticsData.value.porderHasIds = [];
  idArr.value = [];
  tableData.value.forEach((element) => {
    variousStatisticsData.value.porderHasIds.push(element.id);
    if (element.checked) {
      idArr.value.push(element.id);
    }
  });
};
// 删除数据
const delCart = () => {
  // 判断是否有商品选中
  let flog = false;
  for (let j = 0; j < tableData.value.length; j++) {
    let goodsItem = tableData.value[j];
    if (goodsItem.checked) {
      flog = true;
    }
  }
  if (!flog) {
    return proxy.$message.error(proxy.$fanyi("未选择操作的商品"));
  }

  let msg = proxy.$fanyi("是否将选中的商品移除配送单详情");

  proxy.$refs.myDialogddd.open({
    text: msg,
    successBtn: proxy.$fanyi("删除"),
    cancelBtn: proxy.$fanyi("取消"),
    successMethods: () => {
      // on confirm
      // 将选中的选项删除
      for (let j = 0; j < tableData.value.length; j++) {
        let goodsItem = tableData.value[j];
        if (goodsItem.checked) {
          tableData.value.splice(j, 1);
          j--;
        }
      }
      // 确认按钮事件
      proxy.$message.success(proxy.$fanyi("移除成功"));
      // 获取总价
      commodityTotal();
    },
  });
};
const matchHeaderText = (v) => {
  switch (v) {
    case "临时保存":
      return proxy.$fanyi("提出配送单");
    // case "装箱中":
    //   return proxy.$fanyi("报价中");
    //   break;
    // case "等待付款":
    //   return proxy.$fanyi("等待付款");
    //   break;
    default:
      return proxy.$fanyi("配送单详情");
      break;
  }
};
// 取消修改配送单
const cancelModification = () => {
  getData();
};
// 确认编辑配送单
const porderporderEdit = () => {
  let upData = {
    create_type: "send",
  };
  for (let i in porderData.value) {
    upData[i] = porderData.value[i];
  }
  delete upData.porder_status;
  delete upData.porder_status_name;
  proxy.$api.porderporderEdit(upData).then((res) => {
    if (res.code != 0) return proxy.$message.error(proxy.$fanyi(res.msg));
    proxy.$toast(proxy.$fanyi("修改成功"));
    setTimeout(() => {
      proxy.$fun.routerToPage("/user/deliveryList?type=vanning");
    }, 1500);
  });
};
// 获取配送单提出预览数据
const porderSubmitPreview = () => {
  tableData.value = [];
  let ids = proxy.$route.query.id.split(",");
  let newArr = [];
  ids.forEach((element) => {
    newArr.push({ order_detail_id: element });
  });

  proxy.$api
    .porderSubmitPreview({
      stock: newArr,
    })
    .then((res) => {
      tableData.value = res.data.porder_data;
      porder_sn.value = res.data.porder_sn;
      if (res.data.exchange_rate) {
        deliveryOrderExchangeRate.value = res.data.exchange_rate;
      } else {
        deliveryOrderExchangeRate.value = proxy.$store.state.exchangeRate;
      }

      porderData.value = res.data;
      // porderData.value.logistics_id = 4;
      porderData.value.receiver_address = {}; //收件人地址
      porderData.value.importer_address = {}; //进口商地址
      porder_status_name.value = "临时保存";
      // res.data.porder_data.forEach((item) => {
      //   item.client_remark = "";
      // });
      handleData();
      commodityTotal();
      loading.value = false;
    });
};
//获取配送单详情数据
const storageDeliverDetail = () => {
  tableData.value = [];
  proxy.$api
    .storageDeliverDetail({
      porder_sn: proxy.$route.query.pOrder_sn,
    })
    .then((res) => {
      if (res.code != 0 || !res.data) {
        loading.value = false;
        return proxy.$message.error(proxy.$fanyi(res.msg));
      }
      if (res.data.exchange_rate) {
        deliveryOrderExchangeRate.value = res.data.exchange_rate;
      } else {
        deliveryOrderExchangeRate.value = proxy.$store.state.exchangeRate;
      }
      headStr.value = matchHeaderText(res.data.porder_status_name);
      // 如果是提出状态配货员还没有操作的配送单就可以修改
      // is_y_editing=1代表业务员开始修改了
      if (res.data.porder_status < 30 && !res.data.is_y_editing) {
        porder_canEdit.value = true;
      } else {
        porder_canEdit.value = false;
      }
      // 2023/5/11 原型图上说已取消状态和装箱中状态显示的东西一样
      if (res.data.porder_status_name == "已取消") {
        res.data.porder_status_name = "装箱中";
      }
      proxy.$nextTick(() => {
        proxy.$refs.deliveryAddressRef.value1 = res.data.address.importer.title;
        proxy.$refs.deliveryAddressRef.value2 = res.data.address.receiver.title;
      });
      res.data.porder_data = res.data.porder_detail;
      tableData.value = res.data.porder_data;
      porder_sn.value = res.data.porder_sn;
      porderData.value = res.data;
      porder_amount.value = res.data.porder_amount;
      porder_status_name.value = res.data.porder_status_name;
      porder_status_Num.value = res.data.porder_status;
      porder_status_Num_NoChange.value = res.data.porder_status;
      if (res.data.porder_status == "40") {
        porder_status_name.value = "已付款待确认";
      }
      porderData.value.logistics_id = res.data.porder_logistics_id;
      porderData.value.client_remark =
        typeof res.data.porder_client_remark == "string"
          ? res.data.porder_client_remark
          : "";
      porderData.value.receiver_address = {
        name: res.data.address.receiver.contacts,
        company: res.data.address.receiver.company,
        address: res.data.address.receiver.address,
        zip: res.data.address.receiver.zip_code,
        mobile: res.data.address.receiver.mobile,
        tel: res.data.address.receiver.tel,
        name_rome: res.data.address.receiver.contacts_roma,
        address_rome: res.data.address.receiver.address_roma,
        corporate_name: res.data.address.receiver.tax_no,
        account: res.data.address.receiver.bank_no,
        standard_code: res.data.address.receiver.standard_no,
      };
      porderData.value.importer_address = {
        name: res.data.address.importer.contacts,
        company: res.data.address.importer.company,
        address: res.data.address.importer.address,
        zip: res.data.address.importer.zip_code,
        mobile: res.data.address.importer.mobile,
        tel: res.data.address.importer.tel,
        name_rome: res.data.address.importer.contacts_roma,
        address_rome: res.data.address.importer.address_roma,
        corporate_name: res.data.address.importer.tax_no,
        account: res.data.address.importer.bank_no,
        standard_code: res.data.address.importer.standard_no,
      };
      proxy.$refs.deliveryAddressRef.validateAddressData();
      handleData();
      commodityTotal();
      loading.value = false;
    });
};
// 获取数据
const getData = () => {
  loading.value = true;
  if (!proxy.$store.state.userInfo) {
    loading.value = false;
    proxy.$store.commit("changeshowLoginDia", () => {
      proxy.$router.go(0);
    });
    return;
  }
  if (proxy.$route.query.id) {
    porderSubmitPreview();
    headStr.value = proxy.$fanyi("提出配送单");
  } else {
    storageDeliverDetail();
  }
};
internationalLogisticsTransportation();
getData();

defineExpose({
  tableData,
  porder_status_Num_NoChange,
  porder_canEdit,
  userOperationMode,
  opendeliveryAddress,
  oddChoice,
  allChoice,
  porderporderEdit,
  variousStatisticsData,
  commodityTotal,
  checked,
  delCart,
  cancelModification,
  porder_status_name,
  porder_status_Num,
  receiveAdditionalData,
  generateDeliveryOrder,
  translateCostDetailsToCamelCase,
  hasUsedCoupon,
  usedCouponInfo,
  porderData,
  openUseCouponModal,
  porder_amount,
  proceedToPayment,
  openBoxDetail,
  couponList,
  deliveryOrderExchangeRate,
  interList,
});
</script>
<style lang="scss" scoped="scoped">
@import "@/css/mixin.scss";
.pageF6Color {
  min-height: 100vh;
  display: flex;
  justify-content: space-between;
  flex-direction: column;
}
.deliveryOrderModificationPrompt {
  width: 750px;
  height: 60px;
  background: #faf2f2;
  display: flex;
  align-items: center;
  color: #b4272b;
  font-size: 24px;
  padding: 0 30px;
  img {
    width: 30px;
    height: 30px;
    margin-right: 10px;
  }
}
.headerButtonBox {
  button {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    padding: 0 35px;
    background-color: transparent;
    font-size: 24px !important;
    font-weight: 400 !important;
    color: #000000;
  }

  button.redFont {
    font-weight: 600 !important;
  }
}
</style>
<style lang="scss">
.headBtnGroup {
  left: unset !important;
  right: 0px !important;
}
</style>
