<!-- 配送单费用明细 -->
<template>
  <van-action-sheet
    v-model:show="show"
    :title="$fanyi('费用明细')"
    class="costDetailsShweet"
  >
    <div class="costDetailsBox">
      <h1>
        <span class="title">{{ $fanyi("国际运费") }}：</span>
        <span>
          {{ porder_amount.international_logistics_fee_jpy }} 円 ({{
            porder_amount.international_logistics_fee
          }}元)</span
        >
      </h1>
      <p
        v-for="(logisticsItem, logisticsIndex) in logistics_group"
        :key="logisticsIndex + 222"
      >
        <span class="title">{{ logisticsItem.logistics_name }}</span>
        <span>{{ logisticsItem.total_money_rmb }}元</span>
        <span>（請求データ: {{ logisticsItem.amount_data }}）</span>
      </p>

      <h1>
        <span class="title">{{ $fanyi("其他费用") }}：</span>
        <span>
          {{ porder_amount.other_fee_jpy }} 円 ({{
            porder_amount.other_fee
          }}元)</span
        >
      </h1>
      <!-- 其他费用详细组成 -->
      <div class="detailBox">
        <div
          v-for="(porder_boxItem, porder_boxIndex) in porder_box"
          :key="porder_boxIndex + 111"
        >
          <p
            v-for="(
              amountItem, amountIndex
            ) in porder_boxItem.other_amount_detail"
            :key="amountIndex + 111"
          >
            <span class="title otherPrice">{{ amountItem.desc }} &nbsp;</span>
            <span>{{ amountItem.fee }}元</span>
          </p>
        </div>
      </div>

      <h1 v-if="false">
        <span class="title">{{ $fanyi("报关费") }}：</span>
        <!-- 这个费用没有人民币,只显示日元 -->
        <span> {{ porder_amount.service_fee }} 円</span>
      </h1>
      <div class="detailBox">
        <p
          v-for="(
            service_fee_item, service_fee_index
          ) in porder_amount.service_fee_list"
          :key="service_fee_index + 2222"
        >
          <span class="title">{{ service_fee_item.logistics_name }}</span>
          <span>{{ service_fee_item.service_fee_jpy }} 円</span>
        </p>
      </div>

      <p class="currentExchangeRate">
        {{ $fanyi("配送单汇率") }} 1元 =
        {{ $fun.ceil($parent.porderData.exchange_rate) }}円
      </p>
    </div>
  </van-action-sheet>
</template>
<script setup>
import { getCurrentInstance, ref } from "vue";
const { proxy } = getCurrentInstance();
//------------------------ data ----------------------------------------
const show = ref(false);
const porder_amount = ref({});
const logistics_group = ref({});
const porder_box = ref({});
//------------------------ pageLoad -------------------------------------
//------------------------ methods --------------------------------------
const open = (data) => {
  show.value = true;
  console.log(data);
  if (data.porder_amount) {
    porder_amount.value = data.porder_amount;
  }
  if (data.logistics_group) {
    logistics_group.value = data.logistics_group;
  }
  if (data.porder_box) {
    porder_box.value = data.porder_box;
  }
};
defineExpose({ open });
</script>
<style scoped lang="scss">
@import "@/css/mixin.scss";

.costDetailsBox {
  padding: 20px 30px;
  min-height: 485.9px;
  h1 {
    margin-bottom: 10px;
    line-height: 36px;
    display: flex;
    align-items: center;
    span {
      font-size: 24px;
      color: #999999;
      &:last-child {
        color: #000;
      }
    }
  }

  .detailBox {
    margin-bottom: 30px;
  }

  p {
    line-height: 30px;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    span {
      font-size: 20px;
      color: #999999;
    }
  }

  .title {
    width: 170px;
    display: inline-block;
    &.otherPrice {
      width: unset;
      min-width: 140px;
    }
  }
  .currentExchangeRate {
    margin-top: 30px;
    font-size: 20px;
    color: #999999;
  }
}
</style>
<style lang="scss">
.costDetailsShweet {
  .van-action-sheet__header {
    text-align: center !important;
  }
}
</style>
